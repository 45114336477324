<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow" btn-background="bg-gradient-success"
        product="Home Loan"
          totalSteps="12"
        link="/hl/home"
          :dark-mode="true" step="10" />
      </div>
    </div>
  </div>
  <main class="mt-lg-0 mt-4 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row justify-content-end">
            <div
              class="col-6 d-md-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
              <div
                class="position-relative bg-gradient-info h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center">
                <img src="../../assets/img/shapes/pattern-lines.svg" alt="pattern-lines"
                  class="position-absolute opacity-4 start-0" />
                <div class="position-relative">
                  <img class="max-width-500 w-100 position-relative z-index-2"
                    src="../../assets/img/illustrations/rocket-white.png" alt="rocket" />
                </div>
                <h4 class="mt-5 text-white font-weight-bolder">
                  Your journey starts here
                </h4>
                <p class="text-white">
                  Discover the best products curated for you in just a few simple steps
                </p>
                <div class="position-relative">
                  <img
                    class="max-width-500 w-25 mt-5 position-relative z-index-2"
                    src="../../assets/img/Fint_Logo.png"
                    alt="fintrens"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="card card-plain mt-5" v-if="noLoader">
                <div class="card-header   pb-0 text-left">
                  <h6 class="font-weight-bolder text-center text-info text-gradient">
                    Insure Your Loan
                  </h6>
                </div>
                <div class="card-body pb-3">
                      <div class="col-12 gx-0 row align-items-center justify-content-center">
                        <span class=" col-md-1 col-12 row justify-content-center m-0 p-0"> 
                          <span class="col-1 col-md px-0"> <img class=" position-relative z-index-2"
                         style="height: 40px;width: 40px;"
                    src="../../assets/img/apple-touch-icon.png" alt="rocket" /></span>
                         </span>
                      <span class="text-xs text-center ms-md-3 col-md-10 col-12 ">Your life insurance will bear the risk of loan repayment in case of untimely demise</span>
<hr/>
<div class="col-12">
                      <soft-checkbox id="termsCheck" name="terms" v-model="check">
                        <template v-slot:label>
                          <label class=" text-xs text-bold ">
                         ADD ONE TIME PREMIUM OF ₹6,40,000
                           </label>
                        </template>
                      </soft-checkbox>
                    </div>
                    <div class="text-center mt-3 col-sm-5 col-7">
                      <soft-button color="info" variant="gradient" type="submit" full-width @Click="redirect()">NEXT
                        <i class="fas fa-arrow-right text-xs ms-1"></i></soft-button>
                    </div>       
                    </div>
                </div>
              </div>
              <div v-else class="row  justify-content-center align-items-center">
                <div class="col-4 row justify-content-center">  <DotLoader
                  class="row justify-content-center"
                size="40px"
                loading=false
                color="#5fc7ed"
   /> </div>
        <div class="col-12 mt-2 text-nowrap">
  <h6 class="text-center text-info">{{ loaderString }}</h6>
        </div>      
  </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import * as Choices from "choices.js";
import { DotLoader } from "vue3-spinner";

const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";
export default {
  name: "HlCrossSelling",
  components: {
    Navbar,
    SoftButton,
    SoftCheckbox,
    DotLoader
  },
  data() {
    return {
      PinCode: "",
      state: "",
      city: '',
      addressLine1: '',
      addressLine2: '',
      tmpPinCode: "",
      tmpstate: "",
      tmpcity: '',
      tmpaddressLine1: '',
      tmpaddressLine2: '',
      check:'of',
      loadArrayString:['Request is being processed...','Validating Aadhaar information...',
      'Confirming user`s eligibillity...'],
      loaderString:'Request is being processed...',
      loaderCount:0,
      loadTimer:{},
    noLoader:true,
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },

  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  mounted(){
    if (document.getElementById("state")) {
      var language = document.getElementById("state");
      new Choices(language,
      {
        itemSelectText:" ",
        position:"top"
      }
      );
    }
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
  redirect() {
        this.noLoader=false;
        this.loaderString=this.loadArrayString[this.loaderCount];
this.loaderCount++;
if(this.loadArrayString.length<this.loaderCount)
{
  clearTimeout(this.myTimeout);
this.$router.push({ path: "/hl/loanSummary" });
}
else{
  this.myTimOut = setTimeout(() => {
 this.redirect(); 
}, 3000);
}

},

    copyAdresses() {
      this.PinCode = this.tmpPinCode;
      this.state = this.tmpstate;
      this.city = this.tmpcity;
      this.addressLine1 = this.tmpaddressLine1;
      this.addressLine2 = this.tmpaddressLine2;
    },
  },
};
</script>
<style>
hr {
  border: 0;
    height: 1px;
    background: #333;
    background-image: linear-gradient(to right, #ccc, #333, #ccc);
    margin-top: 8px !important;
}
</style>