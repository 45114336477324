<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow" btn-background="bg-gradient-success"
        product="Home Loan"
        link="/hl/home"
          totalSteps="12"
          :dark-mode="true" step="3"  />
      </div>
    </div>
  </div>
  <main class="mt-lg-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row justify-content-end">
            <div
              class="col-4 d-lg-flex d-none d-md-block h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
              <div
                class="position-relative bg-gradient-info h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center">
                <img src="../../assets/img/shapes/pattern-lines.svg" alt="pattern-lines"
                  class="position-absolute opacity-4 start-0" />
                <div class="position-relative">
                  <img class="max-width-500 w-100 position-relative z-index-2"
                    src="../../assets/img/illustrations/rocket-white.png" alt="rocket" />
                </div>
                <h4 class="mt-5 text-white font-weight-bolder">
                  Your journey starts here
                </h4>
                <p class="text-white">
                  Discover the best products curated for you in just a few simple steps
                </p>
                <div class="position-relative">
                  <img
                    class="max-width-500 w-25 mt-5 position-relative z-index-2"
                    src="../../assets/img/Fint_Logo.png"
                    alt="fintrens"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-8 col-12 row flex-row mt-5 mt-md-0  side_Zindex card-plain px-0 mx-0 ">
              <div class="col-12 text-left">
                  <h6 class="font-weight-bolder mt-4 text-center text-info text-gradient">
                    Loan Details
                  </h6>
                </div>
              <div class="col-12 py-3 px-0 mx-0 bg-gradient-info rounded-top row  justify-content-between">
                    <span class="col text-start text-xl text-bold text-white">Home Loan</span>
                    <span class="col text-end text-sm text-bold text-white">₹ 21,30,000  </span>
                  </div>
              <div class=" col-md-6 col-12  card-body">
                <div class=" pb-3 mt-3 pt-0">
                  <div class="row justify-content-between">
                       <div class="col-6 text-center ">
                        <span class="text-xs ">EMI</span><br/>
                      <span class="text-lg  text-bold text-dark" >₹ 12,000</span>
                       </div>
                       <div class="col-6 text-center vertical">
                        <span class="text-xs">Interest Rate</span><br/>
                      <span class="text-lg  text-bold text-dark">10 %</span>
                       </div>
                 
                  </div>
                </div>
                <hr>
                      <label>Loan Amount</label>
                       <soft-input id="loanAmount"  v-model="loanAmount"  type="text" class="mb-0" placeholder="Enter Loan Amount"
                         aria-label="eMail" />
                          <label>Tenure</label>
                      <select
                        id="choices-tenure"
                        class="form-control"
                        name="choices-tenure"
                      >
                        <option value="" selected>Select Tenure</option>
                        <option value="10000">10000</option>
                        <option value="20000">20000</option>
                        <option value="30000">30000</option>
                      </select>                  
              </div>
              <div class=" col-md-6 col-12 ps-2 big-vertical" >
                <div class=" pb-3 mt-3 container">
                  <div class="row justify-content-between">
                    <span class="col text-start text-xs text-bold text-dark">Borrower Margin</span>
                    <span class="col text-end text-sm text-bold text-dark">₹12,000</span>
                  <hr/>
                  </div>
                  <div class="row my-2 justify-content-between">
                    <span class="col text-start text-xs text-bold text-dark">Processing Fee</span>
                    <span class="col text-end text-sm text-bold text-dark">₹5,000</span>
                  <hr/>
                  </div>
                  <div class="row my-2 justify-content-between">
                    <span class="col text-start text-xs text-bold text-dark">Stamp Duty</span>
                    <span class="col text-end text-sm text-bold text-dark">₹900</span>
                  <hr/>
                  </div>
                  <div class="row my-2 justify-content-between">
                    <span class="col-9 text-start text-xs text-bold  text-dark">Documentation Charges</span>
                    <span class="col-3 text-end text-sm text-bold text-dark">₹500</span>
                  </div>
                 
                  <div class="row my-2 py-2 justify-content-between align-items-center bg-light rounded">
                    <span class="col text-start text-xs text-bold text-dark">Net Loan Amount</span>
                    <span class="col text-end text-sm text-bold text-dark">₹ 2,00,000</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row my-4 col-md-8 col-12  side_Zindex justify-content-center">
                  <div class="text-center col-md-7 col-10">
                      <soft-button color="info" variant="gradient" type="button" full-width @Click="redirect()">
                       CONFIRM
                      </soft-button>
                    </div>
                    </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftInput from "@/components/SoftInput.vue";
import * as Choices from "choices.js";
const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";
export default {
  name: "HlLoanDetails",
  components: {
    Navbar,
    SoftInput,
    SoftButton,
  },
  data() {
      return {
        sliderValue: 0,
        loanAmount: 8000,
        minAmount:1000,
        steps:1000,
      }
    },
    
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  mounted() {
    if (document.getElementById("choices-tenure")) {
      let temp = document.getElementById("choices-tenure");
      new Choices(temp,{
        position: 'top',
      });
    }
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    redirect()
    {
      this.$router.push({ path: "/hl/getdetails" });
    },
     sliderChange(e)
     {
     this.sliderValue=e;
     }
  },
  
};
</script>
<style>
hr {
  border: 0;
    height: 1px;
    background: #333;
    background-image: linear-gradient(to right, #ccc, #333, #ccc);
    margin: 0rem !important;
    margin-top: 4px !important;
}
hr.inner {
  border: 0;
    height: 1px;
    width:70%;
    background: #333;
    background-image: linear-gradient(to right, #ccc, #333, #ccc);
   align-self: center !important;
}
.vertical {
  border-left: 1px solid rgb(206, 211, 216);
}
.side_Zindex {
  z-index: 1;
}
@media (min-width:770px) {

.big-vertical {
border-left: 1px solid rgb(206, 211, 216);
height: 73%;
}
}
</style>