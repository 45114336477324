<template>
  <div class="container d-flex justify-content-center ">
    <swiper-tile color="dark" header="Personal Loan" @Click="pl()" icon="fa fa-user fa-xl"></swiper-tile>
    <swiper-tile color="warning" header="Car Loan" icon="fa fa-car fa-xl" @Click="vl()"></swiper-tile>
    <swiper-tile color="info" header="Home Loan" icon="fas fa-home fa-xl" @Click="hl()"></swiper-tile>
    <swiper-tile color="danger" header="Business Loan"></swiper-tile>
  </div>
</template>

<script>
import SwiperTile from "../common/SwiperTile.vue";
export default {
  name: "SwiperContainer",
  components: {
    SwiperTile
  },
  methods: {
    pl()
    {
      this.$router.push({ path: "/pl/home" });
    },
    vl()
    {
      this.$router.push({ path: "/vl/home" });
    },
    hl()
    {
      this.$router.push({ path: "/hl/home" });
    },
  
  },
};
</script>
<style scoped>
.card {
  display: flex;
  border-radius: 10px;
  transition: 0.4s ease-out;
  position: relative;
  left: 0px;
  cursor: pointer;
}

.card:not(:first-child) {
  margin-left: -50px;
}

.card:hover {
  transform: translate(10px, -10px);
  transition: 0.4s ease-out;
}

.card:hover~.card {
  position: relative;
  left: 10px;
  transition: 0.4s ease-out;
}
</style>
