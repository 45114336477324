<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow" btn-background="bg-gradient-success"
        product="Vehicle Loan"
          totalSteps="12"
        link="/vl/home"
          :dark-mode="true" step="9" />
      </div>
    </div>
  </div>
  <main class="mt-5 mt-sm-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row justify-content-end">
            <div
              class="col-4 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
              <div
                class="position-relative bg-gradient-warning h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center">
                <img src="../../assets/img/shapes/pattern-lines.svg" alt="pattern-lines"
                  class="position-absolute opacity-4 start-0" />
                <div class="position-relative">
                  <img class="max-width-500 w-100 position-relative z-index-2"
                    src="../../assets/img/illustrations/rocket-white.png" alt="rocket" />
                </div>
                <h4 class="mt-5 text-white font-weight-bolder">
                  Your journey starts here
                </h4>
                <p class="text-white">
                  Discover the best products curated for you in just a few simple steps
                </p>
                <div class="position-relative">
                  <img
                    class="max-width-500 w-25 mt-5 position-relative z-index-2"
                    src="../../assets/img/Fint_Logo.png"
                    alt="fintrens"
                  />
                </div>
              </div>
            </div>
            <div class="col-xl-8 col-lg-8 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
              <div class="card card-plain mt-`1">
                 <div class="card-header   pb-0 text-left">
                  <h6 class="font-weight-bolder text-center text-warning text-gradient">
                    Income Details
                  </h6>
                </div>
                <div class="card-body pb-3">
                  <form role="form" @submit="redirect()">
                    <div class="row justify-content-center align-items-center mt-3">
                      <div class="col-sm-6 col-12 d-flex justify-content-sm-end">
                        <soft-radio name="salaryType" 
                        
                        @radio-change="(e)=>{this.salaryType='Salaried';}" 
                        v-model="salaryType" checked="true"  
                        value="Salaried">Salaried</soft-radio></div>
                      <div class="col-sm-6 col-12 d-flex justify-content-start">
                        <soft-radio name="salaryType" 
                         @radio-change="(e)=>{this.salaryType='Self Employed';}" 
                         v-model="salaryType" value="Self Employed">
                         Self Employed</soft-radio></div>
                    </div>  
                    <div class="row ">
                      <div class="col-12 col-md-6"> <label class="mt-3">Company Name</label>
                        <soft-input class="mb-0" placeholder="Enter Company Name" v-model="companyName" name="companyName"
                          id="companyName" type="text"  />
                      </div>
                      <div v-if="salaryType=='Self Employed'" class="col-12 col-md-6"> 
                        <label class="mt-3">Annual Turn Over</label>
                        <soft-input class="mb-0" placeholder="Enter Annual Turn Over" v-model="anualTurnOver" name="anualTurnOver"
                          id="anualSalary" type="text" />
                      </div>
                      <div v-else class="col-12 col-md-6"> 
                        <label class="mt-3">Annual Salary</label>
                        <soft-input class="mb-0" placeholder="Enter Annual Salary" v-model="anualSalary" name="anualSalary"
                          id="anualSalary" type="text" />
                      </div>
                    </div>
                    <div class="text-center"><label class="mt-md-4"> Account Statement</label></div>
                    <div class="row justify-content-center">
                      <div class="col-12 col-md-5"><soft-button color="secondary" variant="outline" type="button" full-width
                          class=" mb-0">Net Banking Login </soft-button></div>
                          <hr class="d-md-none my-2"/>
                      <div class="col-12 col-md-5  spliter "><soft-input class="mb-0"
                          v-model="fileLoad" name="fileLoad" id="o" type="file" /></div>
                    </div>

                    <div v-if="salaryType=='Self Employed'" class="row  justify-content-center">
                    <span class="text-center"><label class="mt-md-4">ITR upload {{ salaryType }}</label></span>
                    <div class="col-12 col-md-5"><soft-input class="mb-0"
                          v-model="fileLoad" name="fileLoad" id="o" type="file" /></div>
                        </div>
 
                    <div class="row justify-content-center">
                      <div class="text-center col-6">
                      <soft-button color="warning" variant="gradient" type="submit" full-width
                        class="w-100 mt-4 mb-0">NEXT</soft-button>
                    </div>
                    </div>
                    
                  </form>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftRadio from "@/components/SoftRadio.vue";
const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";
export default {
  name: "VlIncomeDetails",
  components: {
    Navbar,
    SoftInput,
    SoftButton,
    SoftRadio,
  },
  data() {
    return {
      anualSalary: "",
      companyName: "",
      employmentType: '',
      salaryType:"",
      fileLoad:{},
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },

  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    redirect() {
      this.$router.push({ path: "/vl/crossSelling" });
    },


  },
};
</script>
<style type="text/css">


@media (min-width:770px) {

  .spliter {
  border-left: 1px solid rgb(206, 211, 216);
  height: 15%;
}

}

hr {
  border: 0;
    height: 1px;
    background: #333;
    background-image: linear-gradient(to right, #ccc, #333, #ccc);
    margin: 0rem !important;
}
</style>