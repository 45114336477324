<template>
  <div class="container top-0 position-sticky z-index-sticky">
     <div class="row">
       <div class="col-12">
         <navbar is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow" btn-background="bg-gradient-success"
        product="Business Loan"
        totalSteps="7"
        link="/bl/home"
           :dark-mode="true" step="2" />
       </div>
     </div>
   </div>
   <main class="mt-lg-0 mt-6 main-content">
     <section>
       <div class="page-header min-vh-100">
         <div class="container">
           <div class="row justify-content-end">
             <div
               class="col-4 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
               <div
                 class="position-relative bg-gradient-danger h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center">
                 <img src="../../assets/img/shapes/pattern-lines.svg" alt="pattern-lines"
                   class="position-absolute opacity-4 start-0" />
                 <div class="position-relative">
                   <img class="max-width-500 w-100 position-relative z-index-2"
                     src="../../assets/img/illustrations/rocket-white.png" alt="rocket" />
                 </div>
                 <h4 class="mt-5 text-white font-weight-bolder">
                   Your journey starts here
                 </h4>
                 <p class="text-white">
                   Discover the best products curated for you in just a few simple steps
                 </p>
                 <div class="position-relative">
                  <img
                    class="max-width-500 w-25 mt-5 position-relative z-index-2"
                    src="../../assets/img/Fint_Logo.png"
                    alt="fintrens"
                  />
                </div>
               </div>
             </div>
             <div class=" col-lg-8 col-md-7 d-flex flex-column mx-lg-0 mx-auto side_Zindex">
              <div class="card card-plain ">
                <div class="card-header   pb-0 text-left">
                  <h6 class="font-weight-bolder text-center text-danger text-gradient">
                    Business Details
                  </h6>
                </div>
              <div class="card-body pt-0 ">
                  <form role="form" @submit="redirect()">
                    <div class="row justify-content-center col-12  mt-3">
                      <label class=" text-center col-12 ">Are you MSME Registered ?</label>
                      <soft-radio name="msme" class=" col-1 " v-model="msme" checked="true"
                        value="yes">YES</soft-radio>
                      <soft-radio name="msme" class="ms-3 col-1 " v-model="msme"
                        value="no">NO</soft-radio>
                    </div>
                    <div class="row mt-3">
                      <div class="col-lg-6 col-12">
                        <label >Legal Name</label>
                        <soft-input class="mb-0" placeholder="Enter Legal Name" v-model="legalName" name="legalName" id="legalName"
                          type="text" />
                      </div>
                      <div class="col-lg-6 col-12">
                        <label >Trade Name</label>
                        <soft-input class="mb-0" placeholder="Enter Trade Name" v-model="tradeName" name="tradeName" id="tradeName"
                          type="text" />
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-lg-6 col-12">
                        <label > Business Email</label>
                        <soft-input class="mb-0" placeholder="Enter Business Email" v-model="businessEmail" name="businessEmail" id="businessEmail"
                          type="text" />
                      </div>
                      <div class="col-lg-6 col-12">
                        <label >CIN Number</label>
                        <soft-input class="mb-0" placeholder="Enter CIN Number" v-model="cinNumbner" name="cinNumbner" id="cinNumbner "
                          type="text" />
                      </div>
                    </div>
                    <div class="row justify-content-center gx-0">
                      <div class="text-center col-sm-5 col-12">
                        <soft-button color="danger" type="button" @Click="redirect()" variant="gradient" full-width
                          class="mt-4 mb-0">NEXT<i class="fas fa-arrow-right text-xs ms-1"></i></soft-button>
                      </div>
                    </div>
                  </form>
                    </div>
                </div>
             </div>
 
           </div>
         </div>
       </div>
     </section>
   </main>
 </template>
<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftRadio from "@/components/SoftRadio.vue";



const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";
export default {
  name: "BlBusinessDetails",
  components: {
    Navbar,
    SoftInput,
    SoftButton,
    SoftRadio,
  },
    
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    redirect()
    {
      this.$router.push({ path: "/bl/gstOtp" });
    },
  },
  
};
</script>
<style type="text/css">
.side_Zindex {
  z-index: 1;
}

</style>