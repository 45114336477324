<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          link="/pl/home"
          :dark-mode="true"
          :step="9"
        />
      </div>
    </div>
  </div>
  <main class="mt-lg-0 mt-7 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row justify-content-end">
            <div
              class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-dark h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center"
              >
                <img
                  src="../../assets/img/shapes/pattern-lines.svg"
                  alt="pattern-lines"
                  class="position-absolute opacity-4 start-0 "
                />
                <div class="position-relative">
                  <img
                    class="max-width-500 w-100 position-relative z-index-2"
                    src="../../assets/img/illustrations/rocket-white.png"
                    alt="rocket"
                  />
                </div>
                <h4 class="mt-5 text-white font-weight-bolder">
                  Your journey starts here
                </h4>
                <p class="text-white">
                  Discover the best products curated for you in just a few simple steps
                </p>
                <div class="position-relative">
                  <img
                    class="max-width-500 w-25 mt-5 position-relative z-index-2"
                    src="../../assets/img/Fint_Logo.png"
                    alt="fintrens"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 d-flex flex-column mx-lg-0 mx-auto side_Zindex" >
              <div class="row py-2 px-3 mt-4 bg-gradient-dark rounded-top">
              <div class="col-12 h4 text-center  text-white  mb-1">Congratulations</div>
              <div class="col-12 text-center text-sm text-white p-0"> 
                Your  loan is approved. You are now just a step away
                for loan disbursement</div>
            </div>
            <div class="row py-2 px-3 border border-dark ">
              <div class="row mx-2 mt-1 ">
                <div class="col-6 col-md-3 text-center text-nowrap">
                  <p class="text-xs smtext-color mb-0 ">Loan Amount (INR)</p>
                  <p class="mt-0 fw-bolder text-sm text-dark">₹ 60,000</p>
                </div>
                <div class="col-6 col-md-3 text-center">
                  <p class="text-xs smtext-color mb-0">Tenure</p>
                  <p class="mt-0 fw-bolder text-sm text-dark">60 Months</p>
                </div>
                <div class="col-6 col-md-3 text-center text-nowrap">
                  <p class="text-xs smtext-color mb-0">Interest Rate (%)</p>
                  <p class="mt-0 fw-bolder text-sm text-dark">14.25 p.a</p>
                </div>
                <div class="col-6 col-md-3 text-center">
                  <p class="text-xs smtext-color mb-0">EMI Amount</p>
                  <p class="mt-0 fw-bolder text-sm text-dark">₹ 8,470</p>
                </div>
              </div>
              <div class="row mx-2 mt-1">
                <div class="col-6 col-md-3 text-center">
                  <p class="text-xs text-capitalize mb-0">processing fee</p>
                  <p class="mt-0 text-sm text-bolder text-dark">₹ 8,496</p>
                </div>
                <div class="col-6 col-md-3 text-center">
                  <p class="text-xs text-capitalize mb-0">Other Charges</p>
                  <p class="mt-0 text-sm text-bolder text-dark">₹ 0</p>
                </div>
                <div class="col-6 col-md-3 text-center">
                  <p class="text-xs text-capitalize mb-0">Premium</p>
                  <p class="mt-0 text-sm text-bolder text-dark">₹ 8,496</p>
                </div>
                <div class="col-6 col-md-3 text-center">
                  <p class="text-xs text-capitalize mb-0">Account</p>
                  <p class="mt-0 text-sm text-bolder text-dark">2000045678976</p>
                </div>
              </div>
            </div>
            <div class=" row justify-content-center d-flex d-lg-none my-3">
                <div class="bg-dark rounded px-2 py-2 text-center">
                  <div><span class="text-white text-sm">Disbursement Amount</span></div>
                  <div><span class="h6 text-white text-center"> ₹ 60,000</span></div>
                </div>
            </div>
            <div class="row mt-4">
              <div class="row  text-sm text-bold text-dark "><span class="text-center">Steps for disbursement initiation</span></div>

              <div class="px-2 col-lg-8 col-12">

                <soft-checkbox id="termsCheck" name="terms" v-model="check">
                        <template v-slot:label>
                          <label class="custom-control-label font-weight-light">
                            Please carefully review and confirm the loan details provided above  
                           </label>
                        </template>
                      </soft-checkbox>
                      <soft-checkbox id="termsCheck" name="terms" v-model="check">
                        <template v-slot:label>
                          <label class="custom-control-label font-weight-light">
                            Please take the time to read through the Agreement Documents and the Terms and Conditions 
                           </label>
                        </template>
                      </soft-checkbox>
                      <soft-checkbox id="termsCheck" name="terms" v-model="check">
                        <template v-slot:label>
                          <label class="custom-control-label font-weight-light">
                            To register for NACH e-Mandate, <a href="#">click here</a>
                           </label>
                        </template>
                      </soft-checkbox>
                      <soft-checkbox id="termsCheck" name="terms" v-model="check">
                        <template v-slot:label>
                          <label class="custom-control-label font-weight-light">
                            <a href="#">click here</a> to provide your voluntary consent for Aadhaar verification. 
                            (Note: Once your Aadhaar is successfully authenticated, you will be redirected to the next step 
                           </label>
                        </template>
                      </soft-checkbox>

                     
              </div>
              <div class=" col-lg-4 col-12 d-lg-flex align-items-center justify-content-center d-none">
                <div class="bg-gradient-dark rounded px-2 py-2 text-center">
                  <div><span class="text-white text-sm">Disbursement Amount</span></div>
                  <div><span class="h6 text-white text-center"> ₹ 60,000</span></div>
                </div>
            </div><div class="row gx-0 "> <soft-button color="dark" @Click="redirect()" variant="gradient" type="submit" full-width class="w-100 mt-3 mb-0">NEXT <i
                        class="fas fa-arrow-right text-xs ms-1"></i></soft-button></div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import * as Choices from "choices.js";
import Navbar from "@/examples/PageLayout/Navbar.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftButton from "@/components/SoftButton.vue";
const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";
export default {
  name: "PlIllustration",
  components: {
    Navbar,
    SoftButton,
    SoftCheckbox
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  mounted(){
    if (document.getElementById("choices-tenure")) {
      var language = document.getElementById("choices-tenure");
      new Choices(language);
    }
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    redirect()
    {
      this.$router.push({ path: "/pl/accountDetails" });
    },
  },

};
</script>
<style type="text/css">
 .form-check label {
    margin-bottom: 0px;
}
.side_Zindex {
  z-index: 1;
}
</style>