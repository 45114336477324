<template>
 <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow" btn-background="bg-gradient-success"
        link="/pl/home"  
          :dark-mode="true" step="2" />
      </div>
    </div>
  </div>
  <main class="mt-lg-0 mt-8 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row justify-content-end">
            <div
              class="col-4 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
              <div
                class="position-relative bg-gradient-dark h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center">
                <img src="../../assets/img/shapes/pattern-lines.svg" alt="pattern-lines"
                  class="position-absolute opacity-4 start-0" />
                <div class="position-relative">
                  <img class="max-width-500 w-100 position-relative z-index-2"
                    src="../../assets/img/illustrations/rocket-white.png" alt="rocket" />
                </div>
                <h4 class="mt-5 text-white font-weight-bolder">
                  Your journey starts here
                </h4>
                <p class="text-white">
                  Discover the best products curated for you in just a few simple steps
                </p>
                <div class="position-relative">
                  <img
                    class="max-width-500 w-25 mt-5 position-relative z-index-2"
                    src="../../assets/img/Fint_Logo.png"
                    alt="fintrens"
                  />
                </div>
              </div>
            </div>
            <div class="col-xl-8 col-lg-8 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
              <div class="card card-plain">
                <div class=" mt-lg-6  pb-0 text-left">
                  <h6 class="font-weight-bolder text-center text-dark text-gradient">
                    Let’s begin with some necessary information and get you started
                  </h6>
                </div>
                <div class="card-body pb-3 mt-3 pt-0">
                  <form role="form" @submit="redirect()">
                    <div class="row">
                    <div class="col-12 col-lg-6"><label>Mobile Number (linked with Aadhaar)</label>
                      <soft-input id="phoneNumber" v-model="phoneNumber" type="text" class="mb-0" placeholder="Enter your Mobile Number"
                        aria-label="phoneNumber" />
                      <small class="text-xs text-lg-nowrap ">(OTP for verification will be sent to this Mobile
                        Number)</small>
                    </div>
                        <div class="col-lg-6 col-12">
                      <label>E-Mail address</label>
                      <soft-input id="eMail"  v-model="email"  type="text" class="mb-0" placeholder="Enter your E-Mail"
                        aria-label="eMail" />
                      <small class="text-xs text-lg-nowrap">(OTP for verification will be sent to this E-Mail
                        address)</small>
                    </div></div>

                    <div class="row mt-3">
                    <div class="col-lg-6 col-12"><label>Permanent Account Number (PAN)</label>
                      <soft-input id="pan" type="text"  v-model="pan"  placeholder="Enter your PAN" aria-label="pan" />
                    </div>
                        <div class="col-lg-6 col-12">
                      <label>Aadhaar</label>
                      <soft-input id="adharNumber" type="text"  v-model="adhar"  class="mb-0" placeholder="Enter your Aadhaar"
                        aria-label="adharNumber" />
                    </div></div>

                    <div class="d-flex mt-3 align-items-center">
                      <soft-checkbox id="termsCheck" rules="is_not:on" name="terms" v-model="check">
                        <template v-slot:label>
                          <label class="custom-control-label text-justify font-weight-light">
                            I hereby declare that I am currently residing in India and consent to
                             receive and authorize the Bank and its affiliates/ group companies to 
                             reach me/send any communication pertaining to my Account / Card / Loan / Investment 
                             and related services/facilities/special offers,
                             or any other relationship held by me with the Bank...
                            <a href="#" class="font-weight-bolder text-dark"
                             data-bs-toggle="modal" data-bs-target="#modal-default">Read More</a></label>
                        </template>
                      </soft-checkbox>
                    </div>
                    <div class="text-center">
                      <soft-button color="dark" type="button" @Click="redirect()" variant="gradient" full-width class="mt-4 mb-0">NEXT<i
                          class="fas fa-arrow-right text-xs ms-1"></i></soft-button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
<!-- Modal -->
<div>
      <div
        class="modal fade"
        id="modal-default"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modal-default"
        aria-hidden="true"
      >
        <div
          class="modal-dialog w-100 h-100 modal- modal-dialog-centered modal-"
          role="document"
        >
          <div class="modal-content" style="max-height: 70%; max-width: 100%">
            <div class="modal-header">
              <h6 class="modal-title" id="modal-title-default">
                Terms & Conditions
              </h6>
             
            </div>
            <div
              class="overflow-auto container text-justify"
              style="max-width: 100%; max-height: 100%"
            >
              <p>
                I hereby declare that I am currently residing in India
         and consent to receive and authorize the Bank and its 
         affiliates/ group companies to reach me/send any
          communication pertaining to my Account / Card / Loan / Investment and
           related services/facilities/special offers, or any other relationship held by
            me with the Bank, or in relation to various products, offers and services
             provided by  /its group companies, through registered e-mail,
              phone, SMS, WhatsApp messaging, or any other electronic mode or other
               message in personal or social media platforms.
              </p>
              <p>
                 will be sending One
               Time Pin (OTP) on the above mentioned mobile number.
              </p>
              <p>
                This consent will 
               override any registration for DND/DNC registered with personal loan with 
               respect to Account / Card / Loan / Investment / any other relationship with 
               personal loan and any other services/facilities/offers offered by personal loan.
              </p>
              <p>
                I understand and agree that the email provider /WhatsApp or any other 
                service provider can review/monitor the contents shared/communicated through 
                email provider/WhatsApp or other service provider. 
              </p>
              <p>
                 
                I understand that such
                 electronic mode of communication(s) are subject to the terms and conditions 
                 of the respective service provider and agree to comply with the terms of use
                  of email/ WhatsApp, SMS, or any other electronic mode, 
        as applicable and updated/modified by the service provider from time to time.
              </p>
            </div>
            <div class="modal-footer py-0">
              <button
                type="button"
                class="btn btn-link ml-auto text-dark"
                data-bs-dismiss="modal"
                ref="closer"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

<!-- Modal -->
  </main>
</template>
<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";

import SoftButton from "@/components/SoftButton.vue";
const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";
export default {
  name: "PlGetDetails",
  components: {
    Navbar,
    SoftInput,
    SoftButton,
    SoftCheckbox,
  },
    
  data(){
    return {
    
   tagRef:{},

     } },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  mounted() {
   this.tagRef = this.$refs.closer;
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
    this.tagRef.click();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    redirect()
    {
      this.$router.push({ path: "/pl/otp" });

    },
  },
  
};
</script>
<style>
.modal{
    overflow-y: hidden !important;
}
</style>