<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          link="/pl/home"
          :dark-mode="true"
          step="3"
        />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row justify-content-end">
            <div
              class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-dark h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center"
              >
                <img
                  src="../../assets/img/shapes/pattern-lines.svg"
                  alt="pattern-lines"
                  class="position-absolute opacity-4 start-0"
                />
                <div class="position-relative">
                  <img
                    class="max-width-500 w-100 position-relative z-index-2"
                    src="../../assets/img/illustrations/rocket-white.png"
                    alt="rocket"
                  />
                </div>
                <h4 class="mt-5 text-white font-weight-bolder">
                  Your journey starts here
                </h4>
                <p class="text-white">
                  Discover the best products curated for you in just a few simple steps
                </p>
                <div class="position-relative">
                  <img
                    class="max-width-500 w-25 mt-5 position-relative z-index-2"
                    src="../../assets/img/Fint_Logo.png"
                    alt="fintrens"
                  />
                </div>
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto"
            >
              <div class="card card-plain">
                <div class="card-header   pb-0 text-left">
                  <h6 class="font-weight-bolder text-center text-dark text-gradient">
                    Validate Mobile and Email OTP
                  </h6>
                </div>
                <div class="card-body pb-3">
                  <form role="form" @submit="redirect()">
                    <label>Mobile OTP</label>
                    <soft-input
                class="mb-0"
                  placeholder="Enter Mobile OTP"
                  v-model="PhoneOtp"
                  name="otp"
                  id="PhoneOtp"
                  type="text"
                  rules="required:true"
                />
                    <div class="row justify-content-center mt-2">
                      <div class="text-xs text-center text-md-start col-12 col-md-8">(OTP sent on 9XXXXXX968) </div>
                      <div  v-if="mobileTime>0"   class="text-xs text-md-start mt-2 mt-md-0 text-center ms-3 text-nowrap col-12 col-md-3"> Resend in {{ mobileCountTime }}</div>
  <div  v-else  class="text-xs text-center text-md-start mt-2 mt-md-0 text-nowrap col-12 col-md-3"><a @click="(e)=>{this.setMobileTime(); }" class="text-success">Resend OTP</a></div>
                    </div>
                    <label class="mt-4">E-Mail OTP</label>
                    <soft-input 
                  placeholder="Enter Email OTP"
                  v-model="emailOtp"
                  name="otp"
                  id="emailOtp"
                  type="text"
                  rules="required:true"
                />
                <div class="row justify-content-center mt-2">
                      <div class="text-xs text-center text-md-start col-12 col-md-8">(OTP sent on axxxxx.xxxxx.com) </div>
                      <div v-if="emailTime > 0"
                        class="text-xs text-md-start mt-2 mt-md-0 text-center ms-3 text-nowrap col-12 col-md-3"> Resend in
                        {{ emailCountTime }}</div>
                      <div v-else class="text-xs text-center text-md-start mt-2 mt-md-0 text-nowrap col-12 col-md-3"><a
                          @click="(e) => { this.setEmailTime(); }" class="text-success">Resend OTP</a></div>
                    </div>
                    <div class="text-center">
                      <soft-button
                        color="dark"
                        variant="gradient"
                        type="submit"
                        full-width
                        class="w-100 mt-4 mb-0"
                        >NEXT <i class="fas fa-arrow-right text-xs ms-1"></i></soft-button
                      >
                    </div>
                  </form>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";
export default {
  name: "PlOTP",
  components: {
    Navbar,
    SoftInput,
    SoftButton,
  },
  data() {
    return {
      emailOtp: "",
      PhoneOtp: "",

      mobileTime:30000,
      emailTime:60000,

      mobileCountTime:'',
      emailCountTime:'',

      mobileTimeObj:'',
      emailTimeObj:'',

    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  mounted(){
    this.setMobileTime();
    this.setEmailTime();

  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  computed:{
       mobileTimer()
     {
  return this.mobileCountTime;
     },
     emailTimer()
     {
      return this.emailCountTime;
     }

    },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    redirect()
    {
      this.$router.push({ path: "/pl/userDetails" });

    },

    setMobileTime()
    {
      this.mobileTime=30000;
      this.mobileTimeObj = setInterval(this.myMobileTimer ,1000);
    },

myMobileTimer() {
  this.mobileTime=this.mobileTime-1000;
   var minutes = Math.floor((this.mobileTime % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((this.mobileTime % (1000 * 60)) / 1000);
  this.mobileCountTime = minutes+'m ' + seconds+'s'
   if(this.mobileTime==0)
   {
      this.resendEnable = true;
     clearInterval(this.mobileTimeObj);
   }},

   setEmailTime()
    {
      this.emailTime = 60000;
      this.emailTimeObj = setInterval(this.myEmailTimer ,1000);
    },

myEmailTimer() {
  this.emailTime=this.emailTime-1000;
   var minutes = Math.floor((this.emailTime % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((this.emailTime % (1000 * 60)) / 1000);
  this.emailCountTime = minutes+'m ' + seconds+'s'
   if(this.emailTime==0)
   {
      this.resendEnable = true;
     clearInterval(this.emailTimeObj);
   }},
  


    
  },
};
</script>
