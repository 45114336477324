<template>
  <div class="container top-0 position-sticky z-index-sticky">
     <div class="row">
       <div class="col-12">
         <navbar is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow" btn-background="bg-gradient-success"
        product="Business Loan"
        totalSteps="7"
        link="/bl/home"
           :dark-mode="true" step="3" />
       </div>
     </div>
   </div>
   <main class="mt-lg-0 mt-6 main-content">
     <section>
       <div class="page-header min-vh-100">
         <div class="container">
           <div class="row justify-content-end">
             <div
               class="col-4 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
               <div
                 class="position-relative bg-gradient-danger h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center">
                 <img src="../../assets/img/shapes/pattern-lines.svg" alt="pattern-lines"
                   class="position-absolute opacity-4 start-0" />
                 <div class="position-relative">
                   <img class="max-width-500 w-100 position-relative z-index-2"
                     src="../../assets/img/illustrations/rocket-white.png" alt="rocket" />
                 </div>
                 <h4 class="mt-5 text-white font-weight-bolder">
                   Your journey starts here
                 </h4>
                 <p class="text-white">
                   Discover the best products curated for you in just a few simple steps
                 </p>
                 <div class="position-relative">
                  <img
                    class="max-width-500 w-25 mt-5 position-relative z-index-2"
                    src="../../assets/img/Fint_Logo.png"
                    alt="fintrens"
                  />
                </div>
               </div>
             </div>
             <div class="col-xl-8 col-lg-8 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
               <div class="card card-plain">
                 <div class="card-header mt-lg-4  pb-0 text-left">
                   <h6 class="font-weight-bolder text-center text-danger text-gradient">
                   GST Details
                   </h6>
                 </div>
                 <div class="card-body pb-3 pt-0 mt-5">
                  <div class="row align-items-center mb-0">
          <div class="col-12 row d-lg-flex d-none ">
              <div
                class="col-1  text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
              </div>
              <div
                class="col-3  text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                GST IN
              </div>
              <div
                class="col-3  text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Location
              </div>
              <div
                class="col-3  text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >GST User Name
              </div>
              <div
                class="col-2  text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >Action
              </div>
            </div>
            <div class="col-12 row align-items-center mt-3"  v-for="(value, i) in tblData" v-bind:key="i">
              <div class="col-lg-1 col-12 d-lg-block d-none">
                <div class=" px-2 ">
                    <soft-checkbox id="termsCheck" name="terms" v-model="check">
                      </soft-checkbox>
                </div>
              </div>
              <div class="col-lg-3  col-12 text-lg-center mt-2 ">
                <label class="ms-0 d-lg-none d-block">GST IN:</label>
                <span class="text-lg-xxs text-sm text-secondary mb-0">{{ value.gst }}</span>
              </div>
              <div class="col-lg-3  col-12  text-lg-center mt-2">
                <label class="ms-0 d-lg-none d-block">Location:</label>
                  <span class="text-secondary text-sm text-lg-xxs ">{{ value.location }}</span>
              </div>
              <div class="align-middle  text-lg-center text-sm col-lg-3 col-12 mt-2">
                <label class="ms-0 d-lg-none d-block">User Name</label>
                        <soft-input class="mb-0" placeholder="Enter GST User Name" v-model="gstUserName" name="gstUserName" id="gstUserName"
                          type="text" />
              </div>
              <div class="align-middle  ms-0 ps-0 row gx-lg-0 text-lg-center text-xs col-lg-2 col-12 mt-2">
                <div class="text-md-center col-6  col-md-12 ">
                  <a href="#" class="link-danger text-bolder d-none d-lg-inline ">SEND</a>
                  <soft-button color="danger" type="button"
                   variant="outline" full-width class="p-2 d-lg-none">
                    SEND</soft-button>
                    </div>
              </div>
            </div>
            <div class="col-12 row gx-0 justify-content-center">
                <div class="text-center col-6 ">
                  <soft-button color="danger" type="button" @Click="redirect()" variant="gradient" full-width class="mt-4 mb-0">NEXT<i
                           class="fas fa-arrow-right text-xs ms-1"></i></soft-button>
                </div>
              </div>
                      </div>
                 </div>
               </div>
             </div>
 
           </div>
         </div>
       </div>
     </section>
   </main>
 </template>
<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";



const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";
export default {
  name: "BlGstOtp",
  components: {
    Navbar,
    SoftInput,
    SoftButton,
    SoftCheckbox
  },
  data() {
      return {
      tblData:[{"gst":"32AIZHD4762G1ZM","location":"Kerala - Kochi"},{"gst":"32AIZHD4762G1ZM","location":"Kerala - Kochi"}]
      };
    },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    redirect()
    {
      this.$router.push({ path: "/bl/PartnerDetails" });
    },
  },
  
};
</script>
<style type="text/css">
.side_Zindex {
  z-index: 1;
}
</style>