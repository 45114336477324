<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow" btn-background="bg-gradient-success"
          :dark-mode="true" product="Home Loan"
           link="/hl/home" totalSteps="12"
            />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row justify-content-end">
            <div
              class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
              <div
                class="position-relative bg-gradient-info h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center">
                <img src="../../assets/img/shapes/pattern-lines.svg" alt="pattern-lines"
                  class="position-absolute opacity-4 start-0" />
                <div class="position-relative">
                  <img class="max-width-500 w-100 position-relative z-index-2"
                    src="../../assets/img/illustrations/rocket-white.png" alt="rocket" />
                </div>
                <h4 class="mt-5 text-white font-weight-bolder">
                  Your journey starts here
                </h4>
                <p class="text-white">
                  Discover the best products curated for you in just a few simple steps
                </p>
                <div class="position-relative">
                  <img class="max-width-500 w-25 mt-5 position-relative z-index-2" src="../../assets/img/Fint_Logo.png"
                    alt="fintrens" />
                </div>
              </div>
            </div>
            <div class=" col-md-6 d-flex flex-column mx-lg-0 mt-3 mt-lg-0 mx-auto">
              <div class="card card-plain ">
                <SwiperContainer class="mt-5"></SwiperContainer>
                <div class="card-header pb-0 text-center">
                  <div> <i class="fas fa-money-bill text-xs px-1"></i><small><small>Get a Loan up to
                        ₹2,00,00,000</small></small></div>
                  <div><i class="fas fa-clock text-xs px-1"></i><small><small>Flexible Tenure From 15-30 Years</small></small>
                  </div>
                </div>
                <div class="card-body pb-3  mx-lg-6">
                  <form role="form" @submit="redirect()">
                    <select id="choices-loanType" class="form-control" name="choices-loanType">
                        <option value="" selected>Select Loan Type</option>
                        <option value="Home Loans">Home Loans</option>
                        <option value="House Renovation Loans">House Renovation Loans</option>
                        <option value="Home Extension Loans">Home Extension Loans</option>
                        <option value="Plot Loans">Plot Loans</option>
                        <option value="Loan Against Residential Property">Loan Against Residential Property </option>
                        <option value="Non Residential Premises Loans">Non Residential Premises Loans</option>
                        <option value="Loan Against Commercial Property">Loan Against Commercial Property</option>
                      </select>
                    <div class="row justify-content-center mt-3">
                      <label class="text-center col-12 ">Have You Short Listed The Property Transaction?</label>
                      <soft-radio name="propertyTransaction" class=" col-3" v-model="propertyTransaction" checked="true"
                        value="yes">YES</soft-radio>
                      <soft-radio name="propertyTransaction" class="col-3" v-model="propertyTransaction"
                        value="no">NO</soft-radio>
                    </div>
                    <div class="text-center mt-3">
                      <soft-button color="info" variant="gradient" type="submit" full-width @Click="redirect()">NEXT
                        <i class="fas fa-arrow-right text-xs ms-1"></i></soft-button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </main>
  <div>
    <AdsTileContainer class="mx-0"></AdsTileContainer>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <footer-terms></footer-terms>
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script>
import * as Choices from "choices.js";
import Navbar from "@/examples/PageLayout/Navbar.vue";
import Footer from "@/examples/Footer.vue";
import FooterTerms from "@/examples/FooterTerms.vue";
import SoftButton from "@/components/SoftButton.vue";
import AdsTileContainer from "../common/AdsTileContainer.vue";
import SwiperContainer from "./SwiperContainer.vue";
import SoftRadio from "@/components/SoftRadio.vue";



const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";
export default {
  name: "HlVehicleLoan",
  components: {
    Navbar,
    SoftButton,
    Footer,
    FooterTerms,
    AdsTileContainer,
    SwiperContainer,
    SoftRadio,

  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  mounted() {
    if (document.getElementById("choices-loanType")) {
      let temp = document.getElementById("choices-loanType");
      new Choices(temp);
    }
  
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    redirect() {
      this.$router.push({ path: "/hl/propertyDetails" });
    },

  },

};
</script>
<style type="text/css">
.choices {
  margin-bottom: 0px !important;
}
</style>