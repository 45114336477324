<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
          link="/pl/home"
          step="10"
        />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row justify-content-end">
            <div
              class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-dark h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center"
              >
                <img
                  src="../../assets/img/shapes/pattern-lines.svg"
                  alt="pattern-lines"
                  class="position-absolute opacity-4 start-0"
                />
                <div class="position-relative">
                  <img
                    class="max-width-500 w-100 position-relative z-index-2"
                    src="../../assets/img/illustrations/rocket-white.png"
                    alt="rocket"
                  />
                </div>
                <h4 class="mt-5 text-white font-weight-bolder">
                  Your journey starts here
                </h4>
                <p class="text-white">
                  Discover the best products curated for you in just a few simple steps
                </p>
                <div class="position-relative">
                  <img
                    class="max-width-500 w-25 mt-5 position-relative z-index-2"
                    src="../../assets/img/Fint_Logo.png"
                    alt="fintrens"
                  />
                </div>
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto"
            >
              <div class="card card-plain">
                <div class="card-header row justify-content-center  pb-0 ">
                  <p class="m-0 font-weight-bolder  text-center text-dark text-gradient">
                   Account Details
                  </p><br/>
                </div>
                <div class="card-body pb-3">
                  <form role="form" @submit="redirect()">
                    <label class="mt-3">Account Number</label>
                    <soft-input
                class="mb-0"
                  placeholder="Enter Account Number"
                  v-model="accountNumber"
                  name="accountNumber"
                  id="accountNumber"
                  type="password"
                />

                <label class="mt-3">Confirm Account Number</label>
                    <soft-input
                class="mb-0"
                  placeholder="Confirm Account Number"
                  v-model="accountNumberConfirm"
                  name="accountNumberConfirm"
                  id="accountNumberConfirm"
                  type="text"
                />

                    <label class="mt-3">IFSC Code</label>
                    <soft-input
                class="mb-0"
                  placeholder="Enter IFSC Code"
                  v-model="ifscCode"
                  name="ifscCode"
                  id="ifscCode"
                  type="text"
                />
<div class="row justify-content-center mt-3" >
  <soft-radio name="gender" class="col-4 " :checked="true" v-model="accountType" value="savings">Savings</soft-radio>
  <soft-radio name="gender" class="col-4 " v-model="accountType" value="current">Current</soft-radio>
</div>
                    <div class="text-center">
                      <soft-button
                        color="dark"
                        variant="gradient"
                        type="submit"
                        full-width
                        class="w-100 mt-4 mb-0"
                        >Finish <i class="fas fa-arrow-right text-xs ms-1"></i></soft-button
                      >
                    </div>

                  
                  </form>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftRadio from "@/components/SoftRadio.vue";
const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";
export default {
  name: "plAccountDetails",
  components: {
    Navbar,
    SoftInput,
    SoftButton,
    SoftRadio,
  },
  data() {
    return {
      accountType:'',
      ifcCode:'',
      accountNumber:'',
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
 
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    redirect()
    {
      this.$router.push({ path: "/pl/approval" });

    },

    
  },
};
</script>
