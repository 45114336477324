<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
          product="Home Loan"
          totalSteps="12"
          step="6"
        link="/hl/home"
        />
      </div>
    </div>
  </div>
  <main class="mt-md-0 mt-3 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row justify-content-end">
            <div
              class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-info h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center"
              >
                <img
                  src="../../assets/img/shapes/pattern-lines.svg"
                  alt="pattern-lines"
                  class="position-absolute opacity-4 start-0"
                />
                <div class="position-relative">
                  <img
                    class="max-width-500 w-100 position-relative z-index-2"
                    src="../../assets/img/illustrations/rocket-white.png"
                    alt="rocket"
                  />
                </div>
                <h4 class="mt-5 text-white font-weight-bolder">
                  Your journey starts here
                </h4>
                <p class="text-white">
                  Discover the best products curated for you in just a few simple steps
                </p>
                <div class="position-relative">
                  <img
                    class="max-width-500 w-25 mt-5 position-relative z-index-2"
                    src="../../assets/img/Fint_Logo.png"
                    alt="fintrens"
                  />
                </div>
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto"
            >
              <div class="card card-plain">
                <div class="card-body pb-3">
                  <h6 class="font-weight-bolder text-center text-info text-gradient">
                    Personal Details
                  </h6>
                  <form role="form" @submit="redirect()">
                    <label class="mt-3">First Name</label>
                    <soft-input
                class="mb-0"
                  placeholder="Enter First Name"
                  v-model="firstName"
                  name="firstName"
                  id="firstName"
                  type="text"
                />

                    <label class="mt-3">Last Name</label>
                    <soft-input
                class="mb-0"
                  placeholder="Enter Last Name"
                  v-model="lastName"
                  name="lastName"
                  id="lastName"
                  type="text"
                />
                <label class="mt-3">Date of Birth</label>
                <flat-pickr class=" form-control" placeholder="Enter Date of Birth" v-model="dob"/>

                <div class="d-flex justify-content-between mt-3" >
  <soft-radio name="gender" class="ms-5 " v-model="gender" checked="true" value="male">MALE</soft-radio>
  <soft-radio name="gender" class="me-5 ms-2" v-model="gender" value="female">FEMALE</soft-radio>
</div>

                    <div class="text-center">
                      <soft-button
                        color="info"
                        variant="gradient"
                        type="submit"
                        full-width
                        class="w-100 mt-4 mb-0"
                        >NEXT <i class="fas fa-arrow-right text-xs ms-1"></i></soft-button
                      >
                    </div>

                  
                  </form>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftRadio from "@/components/SoftRadio.vue";
import flatPickr from "vue-flatpickr-component";
const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";
export default {
  name: "HlUserDetails",
  components: {
    Navbar,
    SoftInput,
    SoftButton,
    flatPickr,
    SoftRadio,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      gender:'',
      dob:'',
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
 
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    redirect()
    {
      this.$router.push({ path: "/hl/permanentAdress" });

    },

    
  },
};
</script>
