<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
          link="/pl/home"
          btn-background="bg-gradient-success"
          :dark-mode="true"
        />
      </div>
    </div>
  </div>
  <main class="mt-lg-0 mt-6 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row justify-content-end">
            <div
              class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-dark h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center"
              >
                <img
                  src="../../assets/img/shapes/pattern-lines.svg"
                  alt="pattern-lines"
                  class="position-absolute opacity-4 start-0"
                />
                <div class="position-relative">
                  <img
                    class="max-width-500 w-100 position-relative z-index-2"
                    src="../../assets/img/illustrations/rocket-white.png"
                    alt="rocket"
                  />
                </div>
                <h4 class="mt-5 text-white font-weight-bolder">
                  Your journey starts here
                </h4>
                <p class="text-white">
                  Discover the best products curated for you in just a few simple steps
                </p>
                <div class="position-relative">
                  <img
                    class="max-width-500 w-25 mt-5 position-relative z-index-2"
                    src="../../assets/img/Fint_Logo.png"
                    alt="fintrens"
                  />
                </div>
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto"
            >
              <div class="card card-plain">
                <SwiperContainer></SwiperContainer>
                <div class="card-header pb-0 text-left">
                  <div> <i class="fas fa-money-bill text-xs px-1"></i><small><small>Get a  Loan up to ₹50,00,000</small></small></div>
                  <div><i class="fas fa-clock text-xs px-1"></i><small><small>Flexible Tenure From 12-72 Months</small></small></div>
                  <div><i class="fas fa-percent text-xs px-1"></i><small><small>Rate of Interest* as low as 10.50% pa</small></small></div>
                  <div><i class="fas fa-chart-line text-xs px-1"></i><small><small>EMI starting at ₹2,187 per ₹1 lakh</small></small></div>
                </div>
                <div class="card-body pb-3">
                  <form role="form" @submit="redirect()">
                    <label>Get the loan best suited for your wish</label>
                    <soft-input
                      id="loanamount"
                      type="text"
                      icon-dir="left"
                      icon="fa fa-coins"
                      placeholder="How much loan do you require?"
                      aria-label="loanamount"
                    />
                      <select
                        id="choices-tenure"
                        class="form-control"
                        name="choices-tenure"
                      >
                        <option value="" selected>Select a comfortable tenure</option>
                        <option value="12">12 Months</option>
                        <option value="24">24 Months</option>
                        <option value="36">36 Months</option>
                        <option value="48">48 Months</option>
                        <option value="60">60 Months</option>
                        <option value="72">72 Months</option>
                      </select>
                    <div class="text-center">
                      <soft-button
                        color="dark"
                        variant="gradient"
                        type="submit"
                        full-width
                        class="w-100 mt-4 mb-0"
                        >Let's Get Started <i class="fas fa-arrow-right text-xs ms-1"></i></soft-button
                      >
                    </div>
                  </form>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </main>
  <div>
    <AdsTileContainer class="mx-0"></AdsTileContainer>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <footer-terms></footer-terms>
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script>
import * as Choices from "choices.js";
import Navbar from "@/examples/PageLayout/Navbar.vue";
import Footer from "@/examples/Footer.vue";
import FooterTerms from "@/examples/FooterTerms.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
import AdsTileContainer from "../common/AdsTileContainer.vue";
import SwiperContainer from "./SwiperContainer.vue";
const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";
export default {
  name: "PlIllustration",
  components: {
    Navbar,
    SoftInput,
    SoftButton,
    Footer,
    FooterTerms,
    AdsTileContainer,
    SwiperContainer
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  mounted(){
    if (document.getElementById("choices-tenure")) {
      var language = document.getElementById("choices-tenure");
      new Choices(language,{
        itemSelectText:" "
      });
    }
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    redirect()
    {
      this.$router.push({ path: "/pl/getDetails" });

    },
  },

};
</script>
