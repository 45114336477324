<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
          product="Vehicle Loan"
        link="/vl/home"
          totalSteps="12"
        />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row justify-content-end">
            <div
              class="col-4 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-warning h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center"
              >
                <img
                  src="../../assets/img/shapes/pattern-lines.svg"
                  alt="pattern-lines"
                  class="position-absolute opacity-4 start-0"
                />
                <div class="position-relative">
                  <img
                    class="max-width-500 w-100 position-relative z-index-2"
                    src="../../assets/img/illustrations/rocket-white.png"
                    alt="rocket"
                  />
                </div>
                <h4 class="mt-5 text-white font-weight-bolder">
                  Your journey starts here
                </h4>
                <p class="text-white">
                  Discover the best products curated for you in just a few simple steps
                </p>
                <div class="position-relative">
                  <img
                    class="max-width-500 w-25 mt-5 position-relative z-index-2"
                    src="../../assets/img/Fint_Logo.png"
                    alt="fintrens"
                  />
                </div>
              </div>
            </div>
            <div
              class=" col-md-8 d-flex flex-column mx-lg-0 mt-3 mt-lg-0 mx-auto"
            >
              <div class="card card-plain ">
                <SwiperContainer class="mt-5"></SwiperContainer>
                <div class="card-header pb-0 text-center">
                  <div> <i class="fas fa-money-bill text-xs px-1"></i><small><small>Get a  Loan up to ₹50,00,000</small></small></div>
                  <div><i class="fas fa-clock text-xs px-1"></i><small><small>Flexible Tenure From 12-72 Months</small></small></div>
                </div>
                <div class="card-body ">
                  <form role="form" @submit="redirect()">
                    <div class="row">
                    <div class="col-12 col-lg-4"><label>Manufacturer</label>
                      <select
                        id="choices-manufacturer"
                        class="form-control"
                        name="choices-manufacturer" 
                      >
                        <option value="" selected>Select Manufacturer</option>
                        <option value="General Motors">General Motors</option>
                        <option value="BMW">BMW</option>
                        <option value="Mercedes-Benz">Mercedes-Benz</option>
                        <option value="Toyota">Toyota</option>
                      </select>
                    </div>

                        <div class="col-lg-4 col-12">
                          <label>Model</label>
                      <select
                        id="choices-model"
                        class="form-control"
                        name="choices-model"
                      >
                        <option value="" selected>Select Model</option>
                        <option value="Mercedes-Benz S-Class">Mercedes-Benz S-Class</option>
                        <option value="Mercedes-Benz G-Class">Mercedes-Benz G-Class</option>
                        <option value="Mercedes-Benz E-Class">Mercedes-Benz E-Class</option>
                      </select>
                    </div>
                    <div class="col-lg-4 col-12"><label>Variant</label>
                      <select
                        id="choices-variant"
                        class="form-control"
                        name="choices-variant"
                      >
                        <option value="" selected>Select Variant</option>
                        <option value="S-Class S 350d">S-Class S 350d</option>
                        <option value="S-Class S450 4Matic">S-Class S450 4Matic</option>
                        <option value="S-Class S400d 4Matic">S-Class S400d 4Matic</option>
                      </select>                    </div>
                  </div>
                    <div class="row mt-3">
                      <div class="col-lg-6 col-12">
                       <label>Dealer Code</label>
                       <soft-input id="dealerCode"  v-model="dealerCode"  type="text" class="mb-0" placeholder="Enter Dealer Code"
                         aria-label="eMail" />
                     </div>
                        <div class="col-lg-6 col-12">
                      <label>Dealer Name</label>
                      <select
                        id="choices-dealer"
                        class="form-control"
                        name="choices-dealer"
                      >
                        <option value="" selected>Select Dealer</option>
                        <option value="12">VTJ Hyundai</option>
                        <option value="24">Supercarz</option>
                        <option value="36">Spinny Car Hub</option>
                        <option value="48">Anand Carz</option>
                      </select>
                    </div></div>

                    <div class="d-flex mt-1 align-items-center">
                      <soft-checkbox id="termsCheck" rules="is_not:on" name="terms" v-model="check">
                        <template v-slot:label>
                          <label class="custom-control-label text-justify font-weight-light">
                            I hereby declare that I am currently residing in India and consent to
                             receive and authorize the Bank and its affiliates/ group companies ,
                             or any other relationship held by me with the Bank...
                            <a href="#" class="font-weight-bolder text-success"
                             data-bs-toggle="modal" data-bs-target="#modal-default">Read More</a></label>
                        </template>
                      </soft-checkbox>
                    </div>
                    <div class="col-12 row gx-0 justify-content-center">
                <div class="text-center col-6 ">
                  <soft-button color="warning" type="button" @Click="redirect()" variant="gradient" full-width class="mt-4 mb-0">NEXT<i
                          class="fas fa-arrow-right text-xs ms-1"></i></soft-button>
                </div>
              </div>
                  </form>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </main>
  <div>
    <AdsTileContainer class="mx-0"></AdsTileContainer>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <footer-terms></footer-terms>
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script>
import * as Choices from "choices.js";
import Navbar from "@/examples/PageLayout/Navbar.vue";
import Footer from "@/examples/Footer.vue";
import FooterTerms from "@/examples/FooterTerms.vue";
import SoftButton from "@/components/SoftButton.vue";
import AdsTileContainer from "../common/AdsTileContainer.vue";
import SwiperContainer from "./SwiperContainer.vue";
import SoftInput from "@/components/SoftInput.vue";

const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";
export default {
  name: "VlVehicleLoan",
  components: {
    Navbar,
    SoftButton,
    Footer,
    FooterTerms,
    AdsTileContainer,
    SwiperContainer,
    SoftInput,
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  mounted(){
    if (document.getElementById("choices-tenure")) {
      let temp = document.getElementById("choices-tenure");
      new Choices(temp,{
        position: 'top',
      } );
    }
    if (document.getElementById("choices-manufacturer")) {
      let temp = document.getElementById("choices-manufacturer");
      new Choices(temp,{
        itemSelectText:"",
      });
    }
    if (document.getElementById("choices-model")) {
      let temp = document.getElementById("choices-model");
      new Choices(temp,{
        itemSelectText:"",
      });
    }
    if (document.getElementById("choices-variant")) {
      let temp = document.getElementById("choices-variant");
      new Choices(temp,{
        itemSelectText:"",
      });
    }
    if (document.getElementById("choices-dealer")) {
      let temp = document.getElementById("choices-dealer");
      new Choices(temp,{
        position: 'top',
      });
    }
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    redirect()
    {
      this.$router.push({ path: "/vl/review" });

    },
  
  },

};
</script>
<style type="text/css">
.choices {
    margin-bottom: 0px !important;
}
</style>