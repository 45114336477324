<template>
  <!-- Navbar -->
  <nav class="top-0 navbar navbar-expand-lg position-absolute z-index-3"
    :class="[isBlur ? isBlur : 'shadow-none my-2 navbar-transparent w-100']" :style="{
      'background-size': (100 / totalSteps) * step + '%',
      'background-image': 'url(' + require(`../../assets/img/illustrations/progress-background-${color}.png`) + ')'
    }">
    <!-- <div class="container ms-0"> -->
    <div class="row justify-content-between w-100">
      <div class="col-12 col-lg-4">
        <a class="d-flex justify-content-between" :href="link">
          <div class="d-flex align-items-middle" :class="headColor">
            <div class="position-relative  col-1"><img class="max-width-500 w-75  position-relative z-index-2"
                src="/img/Fint_Logo.17b29481.png" alt="fintrens"></div>
            <div class="font-weight-bolder ms-2">Fintrens DLP</div>
            <div class="px-2 d-none d-sm-block"><small><small>({{ product }})</small></small></div>
          </div>
          <div class="px-2 d-none d-sm-block d-lg-none"><small><small>Step</small></small> <strong><small><small>{{ step
          }}</small></small></strong><small><small> of {{ totalSteps }}</small></small></div>
        </a>
      </div>
      <div class="col-12  col-md-8 d-none d-lg-flex justify-content-end align-items-center ">
        <div class="row">
          <div class="col-md-auto col-12 text-center">
            <small><small>Step</small></small> <strong><small><small>{{ step }}</small></small></strong><small><small> of
                {{ totalSteps }}</small></small>
          </div>
          <div class="col-md-auto col-6">
            <strong><small><small><small>IP: </small></small></small></strong><small><small><small> {{ ip
            }}</small></small></small>
          </div>
          <div class="col-md-auto col-6"><strong><small><small><small>Location:
                  </small></small></small></strong><small><small><small> {{ country }}</small></small></small></div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </nav>
</template>

<script>
/*eslint-disable*/
import downArrWhite from "@/assets/img/down-arrow-white.svg";
import downArrBlack from "@/assets/img/down-arrow-dark.svg";

import axios from "axios";


export default {
  name: "Navbar",
  components: {

  },
  props: {
    btnBackground: {
      type: String,
      default: "",
    },
    step: {
      type: String,
      default: "1",
    },
    isBlur: {
      type: String,
      default: "",
    },
    darkMode: {
      type: Boolean,
      default: false
    },
    product: {
      type: String,
      default: "Personal Loan"
    },
    headColor: {
      type: String,
      default: "text-dark text-info"
    },
    totalSteps: {
      type: String,
      default: "11"
    },
    link: {
      type: String,
      default: "/"
    },
    color: {
      type: String,
      default: "dark"
    }

  },
  methods: {
    getProgress: (step, teps) => {


      return `${colorValue} ${sizeValue} ${fullWidthValue} ${activeValue}`;
    },
  },
  data() {
    return {
      downArrWhite,
      downArrBlack,
      country: "",
      ip: "",
    };
  },
  mounted() {
    axios.create({
      baseURL: "https://jsonip.com/"
    }).get().then((response) => {
      this.country = response.data.country;
      this.ip = response.data.ip;
    });
  },
  computed: {
    darkModes() {
      return {
        "text-dark": this.darkMode
      };
    }
  }
};
</script>
<style>
nav {
  background-repeat: repeat-y;
  background-size: 100% auto;
}</style>
