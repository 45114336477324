<template>
  <div class="row pt-5 pb-5">
    <AdsTile variant="gradient" color="info" size="12" header="Get Best Rate" sub-header="You can get the best rate?" :img="error" img-height="200" ></AdsTile>
    <AdsTile variant="gradient" color="warning" size="6" header="Easy Application" sub-header="You can get the best rate?" :img="chatImage" img-height="250"></AdsTile>
    <AdsTile variant="gradient" color="white" size="6" header="Quick" sub-header="You can get the best rate?" :img="lock" img-height="250" text-style="text-dark"></AdsTile>
    <AdsTile variant="gradient" color="danger" size="8" header="Secure" sub-header="You can get the best rate?" :img="lock" img-height="250" ></AdsTile>
    <AdsTile variant="gradient" color="success" size="4" header="Apply Now" sub-header="You can get the best rate?" :img="chatImage" img-height="250" ></AdsTile>
  </div>
</template>

<script>
import AdsTile from "./AdsTile.vue";
import chatImage from "../../assets/img/illustrations/chat.png";
import error from "../../assets/img/illustrations/error-404.png";
import lock from "../../assets/img/illustrations/lock.png";
export default {
  name: "AdsTileContainer",
  components: {
    AdsTile
  },  data() {
    return {
      chatImage,
      error,
      lock
    };
  },
};
</script>
