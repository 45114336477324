<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow" btn-background="bg-gradient-success"
          :dark-mode="true" product="Business Loan" link="/bl/home" totalSteps="7" step="5" />
      </div>
    </div>
  </div>
  <main class="mt-lg-0 mt-4 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row justify-content-end">
            <div
              class="col-4 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
              <div
                class="position-relative bg-gradient-danger h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center">
                <img src="../../assets/img/shapes/pattern-lines.svg" alt="pattern-lines"
                  class="position-absolute opacity-4 start-0" />
                <div class="position-relative">
                  <img class="max-width-500 w-100 position-relative z-index-2"
                    src="../../assets/img/illustrations/rocket-white.png" alt="rocket" />
                </div>
                <h4 class="mt-5 text-white font-weight-bolder">
                  Your journey starts here
                </h4>
                <p class="text-white">
                  Discover the best products curated for you in just a few simple steps
                </p>
                <div class="position-relative">
                  <img class="max-width-500 w-25 mt-5 position-relative z-index-2" src="../../assets/img/Fint_Logo.png"
                    alt="fintrens" />
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-8 d-flex flex-column mx-lg-0 mx-auto">
              <div class="card card-plain mt-5" v-if="noLoader">
                <div class="card-header   pb-0 text-left">
                  <h6 class="font-weight-bolder text-center text-danger text-gradient">
                    ITR & Income Details
                  </h6>
                </div>
                <div class="card-body pb-3">
                  <form role="form">
                    <div class="row ">
                      <div class="col-12 col-md-6"> <label class="mt-3">ITR Previous 3 years</label>
                        <soft-input class="mb-0" v-model="itrLoad" name="itrLoad"
                          placeholder="Upload Document for 3 Years" id="o" type="file" />
                      </div>
                      <div class="col-12 col-md-6">
                        <label class="mt-3">Balance Sheet Previous 3 years</label>
                        <soft-input class="mb-0" v-model="balanceLoad" name="balanceLoad"
                          placeholder="Upload Document for 3 Years" id="o" type="file" />
                      </div>
                    </div>
                    <div class="text-center"><label class="mt-md-4">Account Statement</label></div>
                    <div class="row justify-content-center">
                      <div class="col-12 col-md-5"><soft-button color="secondary" variant="outline" type="button"
                          full-width class=" mb-0">Net Banking Login</soft-button></div>
                      <hr class="d-md-none my-2" />
                      <div class="col-12 col-md-5 spliter"><soft-input class="mb-0" v-model="fileLoad" name="fileLoad"
                          id="o" type="file" /></div>
                    </div>
                    <div class="text-center mt-3">
                      <soft-button color="danger" variant="gradient" type="button" full-width @Click="startTime()">NEXT 
                        <i class="fas fa-arrow-right text-xs ms-1"></i></soft-button>
                    </div>
                  </form>
                </div>
              </div>
              <div v-else class="row  justify-content-center align-items-center">
                <div class="col-4 row justify-content-center">
                  <DotLoader class="row justify-content-center" size="40px" loading=false color="#ed4115" />
                </div>
                <div class="col-12 mt-2 text-nowrap">
                  <h6 class="text-center text-danger text-gradient  ">{{ loaderString }}</h6>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
import { DotLoader } from "vue3-spinner";
import * as Choices from "choices.js";
const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";
export default {
  name: "BlCurrentAdress",
  components: {
    Navbar,
    SoftInput,
    SoftButton,
    DotLoader,
  },
  data() {
    return {
      PinCode: "",
      state: "",
      city: '',
      addressLine1: '',
      addressLine2: '',
      tmpPinCode: "",
      tmpstate: "",
      tmpcity: '',
      tmpaddressLine1: '',
      tmpaddressLine2: '',
      check: 'of',
      loadArrayString: [ 'Request is being processed...','Validating GST Details...',
        'Confirming user`s eligibillity...'],
      loaderString: 'Request is being processed...',
      count:0,
      loaderCount: 0,
      loadTimer: {},
      myTimOut: {},
      noLoader: true,
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },

  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  mounted() {
    if (document.getElementById("state")) {
      var language = document.getElementById("state");
      new Choices(language,
        {
          itemSelectText: " ",
          position: "top"
        }
      );
    }
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

       startTime() {
        this.noLoader=false;
        this.loaderString=this.loadArrayString[this.loaderCount];
this.loaderCount++;
if(this.loadArrayString.length<this.loaderCount)
{
  clearTimeout(this.myTimeout);
this.$router.push({ path: "/bl/loanSummary" });
}
else{
  this.myTimOut = setTimeout(() => {
 this.startTime(); 
}, 3000);
}

},
      copyAdresses() {
        this.PinCode = this.tmpPinCode;
        this.state = this.tmpstate;
        this.city = this.tmpcity;
        this.addressLine1 = this.tmpaddressLine1;
        this.addressLine2 = this.tmpaddressLine2;
      },
    },
  };
</script>
