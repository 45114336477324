<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow" btn-background="bg-gradient-success"
        product="Vehicle Loan"
          totalSteps="12"
          :dark-mode="true" step="2" 
        link="/vl/home"
           />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row justify-content-end">
            <div
              class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-warning h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center"
              >
                <img
                  src="../../assets/img/shapes/pattern-lines.svg"
                  alt="pattern-lines"
                  class="position-absolute opacity-4 start-0"
                />
                <div class="position-relative">
                  <img
                    class="max-width-500 w-100 position-relative z-index-2"
                    src="../../assets/img/illustrations/rocket-white.png"
                    alt="rocket"
                  />
                </div>
                <h4 class="mt-5 text-white font-weight-bolder">
                  Your journey starts here
                </h4>
                <p class="text-white">
                  Discover the best products curated for you in just a few simple steps
                </p>
                <div class="position-relative">
                  <img
                    class="max-width-500 w-25 mt-5 position-relative z-index-2"
                    src="../../assets/img/Fint_Logo.png"
                    alt="fintrens"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6 d-flex flex-column mx-lg-0 mx-auto">
              <div class="card card-plain">
                <div class="card-header mt-lg-6  pb-0 text-left">
                  <h6 class="font-weight-bolder mb-0 text-center text-warning text-gradient">
                    Car Details Confirmation
                  </h6>
                </div>
                <div class="card-body pb-3 mt-3 pt-0">
                  <div class="row justify-content-between align-items-center mb-4">
                    <h6 class="col  text-bold text-secondary">Mercedes-Benz S-Class (S 350d)</h6>
                    <div class="d-flex justify-content-end col-3 ">
                      <a class="text-sm text-bold d-flex align-items-center text-warning" 
                      href="#" data-bs-toggle="modal"
                       data-bs-target="#exampleModal" >
                        <span><i class="me-1 fa fa-pen fa-xs"></i></span>
                        <span class="text-gradient text-warning">Edit</span></a>
                    </div>
                  </div>

                  <div class="row justify-content-between">
                    <span class="col text-start text-xs text-bold text-dark">On Road Price</span>
                    <span class="col text-end text-sm text-bold text-dark">₹ {{onRoadPrice }}</span>
                  <hr/>
                  </div>
                  <div class="row my-2 justify-content-between">
                    <span class="col text-start text-xs text-bold text-dark">Ex-Showroom Price</span>
                    <span class="col text-end text-sm text-bold text-dark">₹ {{ exShowroomPrice }}</span>
                  <hr/>
                  </div>
                  <div class="row my-2 justify-content-between">
                    <span class="col text-start text-xs text-bold text-dark">RTO Charges (Indicative)</span>
                    <span class="col text-end text-sm text-bold text-dark">₹ {{ rtoCharges }}</span>
                  <hr/>
                  </div>
                  <div class="row my-2 justify-content-between">
                    <span class="col text-start text-xs text-bold text-dark">Insurance (Indicative)</span>
                    <span class="col text-end text-sm text-bold text-dark">₹ {{ insurancePrice }}</span>
                  <hr/>
                  </div>
                  <div class="row mt-3 justify-content-center">
                  <div class="text-center col-sm-8 col-md-5 col-12 ">
                      <soft-button color="warning"  variant="gradient" type="button" full-width @Click="redirect()">
                       CONFIRM CAR
                      </soft-button>
                    </div>
                    </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
    <!-- modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Edit Details</h5>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
            <label>On Road Price</label>
                       <soft-input id="onRoadPrice"  v-model="onRoadPrice"  type="text" class="mb-0" placeholder="Enter On Road Price"
                         aria-label="onRoadPrice" />
          </div>
          <div class="form-group">
            <label>Ex-Showroom Price</label>
                       <soft-input id="exShowroomPrice"  v-model="exShowroomPrice"  type="text" class="mb-0" placeholder="Enter Ex-Showroom Price"
                         aria-label="exShowroomPrice" />
          </div>
          <div class="form-group">
            <label>RTO Charges</label>
                       <soft-input id="rtoCharges "  v-model="rtoCharges"  type="text" class="mb-0" placeholder="Enter RTO Charges"
                         aria-label="rtoCharges" />
          </div>
          <div class="form-group">
            <label>Insurance</label>
                       <soft-input id="insurance"  v-model="rtoCharges"    type="text" class="mb-0" placeholder="Enter Insurance"
                         aria-label="insurance" />
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <div class="row w-100 justify-content-end">
          <div class="col-12 col-lg-6 mb-2">
            <soft-button color="secondary" data-bs-dismiss="modal"  variant="gradient" type="button" full-width >
                       Close
                      </soft-button>
          </div>
          <div class="col-12 col-lg-6">
            <soft-button color="warning"  data-bs-dismiss="modal" variant="gradient" type="button" full-width>
                       CONFIRM
                      </soft-button>
          </div>
        </div>
        
       
      </div>
    </div>
  </div>
</div>

    <!-- modal -->
  </main>
</template>
<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";
export default {
  name: "VlReview",
  components: {
    Navbar,
    SoftInput,
    SoftButton,
  },
  data(){
    return{

      onRoadPrice:"2,13,61,046",
      exShowroomPrice:"1,71,00,000",
      rtoCharges:"35,91,000",
      insurancePrice:"6,70,046",

    }
  },
    
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  mounted() {

  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
    if(document.getElementsByClassName('modal-backdrop').length>0){
    document.getElementsByClassName('modal-backdrop')[0].remove('show');
    }

  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    redirect()
    {
      this.$router.push({ path: "/vl/loanDetails" });
    },
    edit(){
      this.onRoadPrice = document.getElementById("onRoadPrice").value;
      this.exShowroomPrice = document.getElementById("exShowroomPrice").value;
      this.rtoCharges = document.getElementById("rtoCharges").value;
      this.insurancePrice = document.getElementById("insurance").value;
    }
  },
  
};
</script>
<style>
hr {
  border: 0;
    height: 1px;
    background: #333;
    background-image: linear-gradient(to right, #ccc, #333, #ccc);
    margin: 0rem !important;
    margin-top: 4px !important;
}
</style>