<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow" btn-background="bg-gradient-success"
          totalSteps="12"
          :dark-mode="true" step="12"
        link="/hl/home"
          product="Home Loan"
          />
      </div>
    </div> 
  </div>
  <main class="mt-lg-0  main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row justify-content-end">
            <div
              class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
              <div
                class="position-relative bg-gradient-info h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center">
                <img src="../../assets/img/shapes/pattern-lines.svg" alt="pattern-lines"
                  class="position-absolute opacity-4 start-0" />
                <div class="position-relative">
                  <img class="max-width-500 w-100 position-relative z-index-2"
                    src="../../assets/img/illustrations/rocket-white.png" alt="rocket" />
                </div>
                <h4 class="mt-5 text-white font-weight-bolder">
                  Your journey starts here
                </h4>
                <p class="text-white">
                  Discover the best products curated for you in just a few simple steps
                </p>
                <div class="position-relative">
                  <img
                    class="max-width-500 w-25 mt-5 position-relative z-index-2"
                    src="../../assets/img/Fint_Logo.png"
                    alt="fintrens"
                  />
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column z-index-3 mx-lg-0 mx-auto">
              <div class="col-12 text-center py-3"><i class="fa fa-check-circle fa-5x text-success"></i></div>
           <h6 class="text-center mb-0"> Congratulations!</h6>
            <h6 class="text-center">Please contact the branch with the pre approved sanction letter</h6>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import * as Choices from "choices.js";
const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";
export default {
  name: "HlAprooval",
  components: {
    Navbar,
  },
  data() {
    return {
      PinCode: "",
      state: "",
      city: '',
      addressLine1: '',
      addressLine2: '',
      tmpPinCode: "",
      tmpstate: "",
      tmpcity: '',
      tmpaddressLine1: '',
      tmpaddressLine2: '',
      check:'of'
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },

  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  mounted(){
    if (document.getElementById("state")) {
      var language = document.getElementById("state");
      new Choices(language,
      {
        itemSelectText:" ",
        position:"bottom"
      }
      );
    }
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
  },
};
</script>
