<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow" btn-background="bg-gradient-success"
          :dark-mode="true" product="Business Loan" :step="6" totalSteps="7" link="/bl/home" />
      </div>
    </div>
  </div>
  <main class="mt-lg-0 mt-7 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row justify-content-end">
            <div
              class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
              <div
                class="position-relative bg-gradient-danger h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center">
                <img src="../../assets/img/shapes/pattern-lines.svg" alt="pattern-lines"
                  class="position-absolute opacity-4 start-0 " />
                <div class="position-relative">
                  <img class="max-width-500 w-100 position-relative z-index-2"
                    src="../../assets/img/illustrations/rocket-white.png" alt="rocket" />
                </div>
                <h4 class="mt-5 text-white font-weight-bolder">
                  Your journey starts here
                </h4>
                <p class="text-white">
                  Discover the best products curated for you in just a few simple steps
                </p>
                <div class="position-relative">
                  <img class="max-width-500 w-25 mt-5 position-relative z-index-2" src="../../assets/img/Fint_Logo.png"
                    alt="fintrens" />
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 d-flex flex-column mx-lg-0 mx-auto side_Zindex">
              <div class="row py-2 px-3 mt-4 bg-gradient-danger rounded-top">
                <div class="col-12 h4 text-center  text-white  mb-1">Congratulations</div>
                <div class="col-12 text-center text-sm text-white p-0">
                  Your loan is approved. You are now just a step away
                  for loan disbursement</div>
              </div>
              <div class="row py-2 px-3 border border-danger ">
                <div class="row mx-2 mt-1 ">
                  <div class="col-6 col-md-3 text-center text-nowrap">
                    <p class="text-xs smtext-color mb-0 ">Loan Amount (INR)</p>
                    <p class="mt-0 fw-bolder text-sm text-dark">₹ 15,00,000</p>
                  </div>
                  <div class="col-6 col-md-3 text-center">
                    <p class="text-xs smtext-color mb-0">Tenure</p>
                    <p class="mt-0 fw-bolder text-sm text-dark">15 Years</p>
                  </div>
                  <div class="col-6 col-md-3 text-center text-nowrap">
                    <p class="text-xs smtext-color mb-0">Interest Rate (%)</p>
                    <p class="mt-0 fw-bolder text-sm text-dark">10.25 p.a</p>
                  </div>
                  <div class="col-6 col-md-3 text-center">
                    <p class="text-xs smtext-color mb-0">EMI Amount</p>
                    <p class="mt-0 fw-bolder text-sm text-dark">₹ 3,600</p>
                  </div>
                </div>
                <div class="row mx-2 mt-1">
                  <div class="col-6 col-md-4 text-center">
                    <p class="text-xs text-capitalize mb-0">processing fee</p>
                    <p class="mt-0 text-sm text-bolder text-dark">₹ 500</p>
                  </div>
                  <div class="col-6 col-md-4 text-center">
                    <p class="text-xs text-capitalize mb-0">Other Charges</p>
                    <p class="mt-0 text-sm text-bolder text-dark">₹ 0</p>
                  </div>
                  <div class="col-12 col-md-4 text-center">
                    <p class="text-xs text-capitalize mb-0">Premium</p>
                    <p class="mt-0 text-sm text-bolder text-dark">₹ 5,500</p>
                  </div>
                </div>
              </div>
              <div class=" row justify-content-center d-flex d-lg-none my-3">
                <div class="bg-gradient-danger rounded px-2 py-2 text-center">
                  <div><span class="text-white text-sm">Disbursement Amount</span></div>
                  <div><span class="h6 text-white text-center"> ₹ 2,40,000</span></div>
                </div>
              </div>

              <div class="row gx-0 col-12 justify-content-center mt-1">
                <span class="col-6 text-start text-xs text-nowrap text-bold ">
                  <a class="text-xs text-bold d-flex align-items-center text-danger" href="#">
                    <span><i class="fas fa-download me-2"></i></span>
                    <span class="text-gradient text-danger">Download approval sheet</span></a>
                </span>
                <span class="col-6 text-end text-xs text-bold  "> <a href="#" class="text-danger text-gradient" data-bs-toggle="modal"
                    data-bs-target=".modal">Terms & Conditions</a></span>
              </div>
              <div class="col-12 row gx-0 justify-content-center">
                <div class="text-center col-5 ">
                  <soft-button color="danger" type="button" @Click="redirect()" variant="gradient" full-width
                    class="mt-4 mb-0">
                    CONFIRM
                  </soft-button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
    <!-- Modal -->
    <div>
      <div class="modal fade" id="modal-default1" tabindex="-1" role="dialog" aria-labelledby="modal-default1"
        aria-hidden="true">
        <div class="modal-dialog w-100 h-100 modal- modal-dialog-centered modal-" role="document">
          <div class="modal-content" style="max-height: 70%; max-width: 100%">
            <div class="modal-header">
              <h6 class="modal-title" id="modal-title-default">
                Terms & Conditions
              </h6>

            </div>
            <div class="overflow-auto container text-justify text-xs" style="max-width: 100%; max-height: 100%">
              <p>
                I hereby declare that I am currently residing in India
                and consent to receive and authorize the Bank and its
                affiliates/ group companies to reach me/send any
                communication pertaining to my Account / Card / Loan / Investment and
                related services/facilities/special offers, or any other relationship held by
                me with the Bank, or in relation to various products, offers and services
                provided by /its group companies, through registered e-mail,
                phone, SMS, WhatsApp messaging, or any other electronic mode or other
                messag in personal loan or social media platforms.
              </p>
              <p>
                will be sending One
                Time Pin (OTP) on the abovementioned mobile number.
              </p>
              <p>
                This consent will
                override any registration for DND/DNC registered with personal loan with
                respect to Account / Card / Loan / Investment / any other relationship with
                personal loan and any other services/facilities/offers offered by personal loan.
              </p>
              <p>
                I understand and agree that the email provider /WhatsApp or any other
                service provider can review/monitor the contents shared/communicated through
                email provider/WhatsApp or other service provider.
              </p>
              <p>

                I understand that such
                electronic mode of communication(s) are subject to the terms and conditions
                of the respective service provider and agree to comply with the terms of use
                of email/ WhatsApp, SMS, or any other electronic mode,
                as applicable and updated/modified by the service provider from time to time.
              </p>
            </div>
            <div class="modal-footer py-0">
              <button type="button" class="btn btn-link ml-auto text-danger text-gradient" 
              data-bs-dismiss="modal"
              ref="closer">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
  </main>
</template>

<script>
import * as Choices from "choices.js";
import Navbar from "@/examples/PageLayout/Navbar.vue";
import SoftButton from "@/components/SoftButton.vue";
const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";
export default {
  name: "BlLoanSummary",
  components: {
    Navbar,
    SoftButton,
    // SoftCheckbox
  },
  data(){
    return {
   tagRef:{},
     } },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  mounted() {
    if (document.getElementById("choices-tenure")) {
      var language = document.getElementById("choices-tenure");
      new Choices(language);
    }
   this.tagRef = this.$refs.closer;

  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
    this.tagRef.click();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    redirect() {
      this.$router.push({ path: "/bl/approval" });
    },
  },

};
</script>
<style type="text/css">
.form-check label {
  margin-bottom: 0px;
}

.side_Zindex {
  z-index: 1;
}
</style>