import { createRouter, createWebHistory } from "vue-router";
import Default from "../views/dashboards/Default.vue";
import Automotive from "../views/dashboards/Automotive.vue";
import SmartHome from "../views/dashboards/SmartHome.vue";
import VRDefault from "../views/dashboards/vr/VRDefault.vue";
import VRInfo from "../views/dashboards/vr/VRInfo.vue";
import CRM from "../views/dashboards/CRM.vue";
import Overview from "../views/pages/profile/Overview.vue";
import Teams from "../views/pages/profile/Teams.vue";
import Projects from "../views/pages/profile/Projects.vue";
import General from "../views/pages/projects/General.vue";
import Timeline from "../views/pages/projects/Timeline.vue";
import NewProject from "../views/pages/projects/NewProject.vue";
import Pricing from "../views/pages/Pricing.vue";
import RTL from "../views/pages/Rtl.vue";
import Charts from "../views/pages/Charts.vue";
import SweetAlerts from "../views/pages/SweetAlerts.vue";
import Notifications from "../views/pages/Notifications.vue";
import Kanban from "../views/applications/Kanban.vue";
import Wizard from "../views/applications/wizard/Wizard.vue";
import DataTables from "../views/applications/DataTables.vue";
import Calendar from "../views/applications/Calendar.vue";
import Analytics from "../views/applications/analytics/Analytics.vue";
import EcommerceOverview from "../views/ecommerce/overview/Overview.vue";
import NewProduct from "../views/ecommerce/products/NewProduct.vue";
import EditProduct from "../views/ecommerce/EditProduct.vue";
import ProductPage from "../views/ecommerce/ProductPage.vue";
import ProductsList from "../views/ecommerce/ProductsList.vue";
import OrderDetails from "../views/ecommerce/Orders/OrderDetails";
import OrderList from "../views/ecommerce/Orders/OrderList";
import Referral from "../views/ecommerce/Referral";
import Reports from "../views/pages/Users/Reports.vue";
import NewUser from "../views/pages/Users/NewUser.vue";
import Settings from "../views/pages/Account/Settings.vue";
import Billing from "../views/pages/Account/Billing.vue";
import Invoice from "../views/pages/Account/Invoice.vue";
import Security from "../views/pages/Account/Security.vue";
import Widgets from "../views/pages/Widgets.vue";
import Basic from "../views/auth/signin/Basic.vue";
import Cover from "../views/auth/signin/Cover.vue";

import Illustration from "../views/auth/signin/Illustration.vue";
import ResetBasic from "../views/auth/reset/Basic.vue";
import ResetCover from "../views/auth/reset/Cover.vue";
import ResetIllustration from "../views/auth/reset/Illustration.vue";
import VerificationBasic from "../views/auth/verification/Basic.vue";
import VerificationCover from "../views/auth/verification/Cover.vue";
import VerificationIllustration from "../views/auth/verification/Illustration.vue";
import SignupBasic from "../views/auth/signup/Basic.vue";
import SignupCover from "../views/auth/signup/Cover.vue";

import PlHome from "../views/pl/Home.vue";
import PlGetDetails from "../views/pl/GetDetails.vue";
import PlOtp from "../views/pl/Otp.vue";
import PlUserDetails from "../views/pl/UserDetails.vue";
import PlPermanentAdress from "../views/pl/PermanentAdress.vue";
import PlCurrentAdress from "../views/pl/CurrentAdress.vue";
import PlIncomeDetails from "../views/pl/IncomeDetails.vue";
import PlOffer from "../views/pl/Offer.vue";
import PlAccountDetails from "../views/pl/AccountDetails.vue";
import PlLoanSummary from "../views/pl/LoanSummary.vue";
import PlApproval from "../views/pl/Approval.vue";


import VlHome from "../views/vl/Home.vue";
import Vlreview from "../views/vl/Review.vue";
import VlGetDetails from "../views/vl/GetDetails.vue";
import VlOtp from "../views/vl/Otp.vue";
import VlUserDetails from "../views/vl/UserDetails.vue";
import VlPermanentAdress from "../views/vl/PermanentAdress.vue";
import VlCurrentAdress from "../views/vl/CurrentAdress.vue";
import VlIncomeDetails from "../views/vl/IncomeDetails.vue";
import VlLoanSummary from "../views/vl/LoanSummary.vue";
import VlApproval from "../views/vl/Approval.vue";
import VlLoanDetails from "../views/vl/LoanDetails.vue";
import VlCrossSelling from "../views/vl/CrossSelling.vue";

import HlHome from "../views/hl/Home.vue";
import HlGetDetails from "../views/hl/GetDetails.vue";
import HlOtp from "../views/hl/Otp.vue";
import HlUserDetails from "../views/hl/UserDetails.vue";
import HlPermanentAdress from "../views/hl/PermanentAdress.vue";
import HlCurrentAdress from "../views/hl/CurrentAdress.vue";
import HlIncomeDetails from "../views/hl/IncomeDetails.vue";
import HlLoanSummary from "../views/hl/LoanSummary.vue";
import HlApproval from "../views/hl/Approval.vue";
import HlLoanDetails from "../views/hl/LoanDetails.vue";
import HlCrossSelling from "../views/hl/CrossSelling.vue";
import HlPropertyDetails from "../views/hl/PropertyDetails.vue";


import BlHome from "../views/bl/Home.vue";
import BlGetDetails from "../views/bl/GetDetails.vue";
import BlOtp from "../views/bl/Otp.vue";
import BlPartnerDetails from "../views/bl/PartnerDetails.vue";
import BlLoanSummary from "../views/bl/LoanSummary.vue";
import BlApproval from "../views/bl/Approval.vue";
import BlLoanDetails from "../views/bl/LoanDetails.vue";
import BlBusinessDetails from "../views/bl/BusinessDetails.vue";
import BlGstOtp from "../views/bl/GstOtp.vue";
import BlItrDetails from "../views/bl/ItrDetails.vue";

import Error404 from "../views/auth/error/Error404.vue";
import Error500 from "../views/auth/error/Error500.vue";
import lockBasic from "../views/auth/lock/Basic.vue";
import lockCover from "../views/auth/lock/Cover.vue";
import lockIllustration from "../views/auth/lock/Illustration.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/vl/home",
  },
  {
    path: "/dashboards/dashboard-default",
    name: "Default",
    component: Default,
  },
  {
    path: "/dashboards/automotive",
    name: "Automotive",
    component: Automotive,
  },
  {
    path: "/dashboards/smart-home",
    name: "Smart Home",
    component: SmartHome,
  },
  {
    path: "/dashboards/vr/vr-default",
    name: "VR Default",
    component: VRDefault,
  },
  {
    path: "/dashboards/vr/vr-info",
    name: "VR Info",
    component: VRInfo,
  },
  {
    path: "/dashboards/crm",
    name: "CRM",
    component: CRM,
  },
  {
    path: "/pages/profile/overview",
    name: "Profile Overview",
    component: Overview,
  },
  {
    path: "/pages/profile/teams",
    name: "Teams",
    component: Teams,
  },
  {
    path: "/pages/profile/projects",
    name: "All Projects",
    component: Projects,
  },
  {
    path: "/pages/projects/general",
    name: "General",
    component: General,
  },
  {
    path: "/pages/projects/timeline",
    name: "Timeline",
    component: Timeline,
  },
  {
    path: "/pages/projects/new-project",
    name: "New Project",
    component: NewProject,
  },
  {
    path: "/pages/pricing-page",
    name: "Pricing Page",
    component: Pricing,
  },
  {
    path: "/pages/rtl-page",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/pages/charts",
    name: "Charts",
    component: Charts,
  },
  {

    path: "/pages/widgets",
    name: "Widgets",
    component: Widgets,
  },
  {
    path: "/pages/sweet-alerts",
    name: "Sweet Alerts",
    component: SweetAlerts,
  },
  {
    path: "/pages/notifications",
    name: "Notifications",
    component: Notifications,
  },
  {
    path: "/applications/kanban",
    name: "Kanban",
    component: Kanban,
  },
  {
    path: "/applications/wizard",
    name: "Wizard",
    component: Wizard,
  },
  {
    path: "/applications/data-tables",
    name: "Data Tables",
    component: DataTables,
  },
  {
    path: "/applications/calendar",
    name: "Calendar",
    component: Calendar,
  },
  {
    path: "/applications/analytics",
    name: "Analytics",
    component: Analytics,
  },
  {
    path: "/ecommerce/overview",
    name: "Overview",
    component: EcommerceOverview,
  },
  {
    path: "/ecommerce/products/new-product",
    name: "New Product",
    component: NewProduct,
  },
  {
    path: "/ecommerce/products/edit-product",
    name: "Edit Product",
    component: EditProduct,
  },
  {
    path: "/ecommerce/products/product-page",
    name: "Product Page",
    component: ProductPage,
  },
  {
    path: "/ecommerce/products/products-list",
    name: "Products List",
    component: ProductsList,
  },
  {
    path: "/ecommerce/Orders/order-details",
    name: "Order Details",
    component: OrderDetails,
  },
  {
    path: "/ecommerce/Orders/order-list",
    name: "Order List",
    component: OrderList,
  },
  {
    path: "/ecommerce/referral",
    name: "Referral",
    component: Referral,
  },
  {
    path: "/pages/users/reports",
    name: "Reports",
    component: Reports,
  },
  {
    path: "/pages/users/new-user",
    name: "New User",
    component: NewUser,
  },
  {
    path: "/pages/account/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/pages/account/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/pages/account/invoice",
    name: "Invoice",
    component: Invoice,
  },
  {
    path: "/pages/account/Security",
    name: "Security",
    component: Security,
  },
  {
    path: "/authentication/signin/basic",
    name: "Signin Basic",
    component: Basic,
  },
  {
    path: "/authentication/signin/cover",
    name: "Signin Cover",
    component: Cover,
  },
  {
    path: "/Signin/illustration",
    name: "Signin Illustration",
    component: Illustration,
  },
  {
    path: "/authentication/reset/basic",
    name: "Reset Basic",
    component: ResetBasic,
  },
  {
    path: "/authentication/reset/cover",
    name: "Reset Cover",
    component: ResetCover,
  },
  {
    path: "/authentication/reset/illustration",
    name: "Reset Illustration",
    component: ResetIllustration,
  },
  {
    path: "/authentication/lock/basic",
    name: "Lock Basic",
    component: lockBasic,
  },
  {
    path: "/authentication/lock/cover",
    name: "Lock Cover",
    component: lockCover,
  },
  {
    path: "/authentication/lock/illustration",
    name: "Lock Illustration",
    component: lockIllustration,
  },
  {
    path: "/authentication/verification/basic",
    name: "Verification Basic",
    component: VerificationBasic,
  },
  {
    path: "/authentication/verification/cover",
    name: "Verification Cover",
    component: VerificationCover,
  },
  {
    path: "/authentication/verification/illustration",
    name: "Verification Illustration",
    component: VerificationIllustration,
  },
  {
    path: "/authentication/signup/basic",
    name: "Signup Basic",
    component: SignupBasic,
  },
  {
    path: "/authentication/signup/cover",
    name: "Signup Cover",
    component: SignupCover,
  },
  {
    path: "/pl/home",
    name: "Pl Home",
    component: PlHome,
  },
  {
    path: "/pl/getDetails",
    name: "Pl Get Details",
    component: PlGetDetails,
  },
  {
    path: "/pl/otp",
    name: "Pl OTP",
    component: PlOtp,
  },
  {
    path: "/pl/UserDetails",
    name: "Pl User Details",
    component: PlUserDetails,
  },
  {
    path: "/pl/permanentAdress",
    name: "Pl User Adress",
    component: PlPermanentAdress,
  },
  {
    path: "/pl/currentAdress",
    name: "Pl Current Adress",
    component: PlCurrentAdress,
  },
  {
    path: "/pl/incomeDetails",
    name: "Pl Income Details",
    component: PlIncomeDetails,
  },
  {
    path: "/pl/offer",
    name: "Pl offer",
    component: PlOffer,
  },
  {
    path: "/pl/accountDetails",
    name: "Pl Account Details",
    component: PlAccountDetails,
  },
  {
    path: "/pl/loanSummary",
    name: "Pl Loan Summary",
    component: PlLoanSummary,
  },
  {
    path: "/pl/approval",
    name: "Pl Approval",
    component: PlApproval,
  },
  {
    path: "/vl/home",
    name: "Vl Home",
    component: VlHome,
  },

  {
    path: "/vl/review",
    name: "vl Review",
    component: Vlreview,
  },
  {
    path: "/vl/getdetails",
    name: "vl GetDetails",
    component: VlGetDetails,
  },
  {
    path: "/vl/otp",
    name: "vl OTP",
    component: VlOtp,
  },
  {
    path: "/vl/UserDetails",
    name: "vl User Details",
    component: VlUserDetails,
  },
  {
    path: "/vl/permanentAdress",
    name: "Vl Permanent Adress",
    component: VlPermanentAdress,
  },
  {
    path: "/vl/currentAdress",
    name: "Vl Current Adress",
    component: VlCurrentAdress,
  },
  {
    path: "/vl/incomeDetails",
    name: "Vl Income Details",
    component: VlIncomeDetails,
  },
  {
    path: "/vl/loanSummary",
    name: "Vl Loan Summary",
    component: VlLoanSummary,
  },
  {
    path: "/vl/approval",
    name: "Vl Approval",
    component: VlApproval,
  },
  {
    path: "/vl/loanDetails",
    name: "Vl LoanDetails",
    component: VlLoanDetails,
  },
  {
    path: "/vl/crossSelling",
    name: "Vl CrossSelling",
    component: VlCrossSelling,
  },



  {
    path: "/hl/home",
    name: "Hl Home",
    component: HlHome,
  },

  {
    path: "/hl/getdetails",
    name: "Hl GetDetails",
    component: HlGetDetails,
  },
  {
    path: "/hl/otp",
    name: "Hl OTP",
    component: HlOtp,
  },
  {
    path: "/hl/UserDetails",
    name: "Hl User Details",
    component: HlUserDetails,
  },
  {
    path: "/hl/permanentAdress",
    name: "Hl Permanent Adress",
    component: HlPermanentAdress,
  },
  {
    path: "/hl/currentAdress",
    name: "Hl Current Adress",
    component: HlCurrentAdress,
  },
  {
    path: "/hl/incomeDetails",
    name: "Hl Income Details",
    component: HlIncomeDetails,
  },
  {
    path: "/hl/loanSummary",
    name: "hl Loan Summary",
    component: HlLoanSummary,
  },
  {
    path: "/hl/approval",
    name: "Hl Approval",
    component: HlApproval,
  },
  {
    path: "/hl/loanDetails",
    name: "Hl LoanDetails",
    component: HlLoanDetails,
  },
  {
    path: "/hl/crossSelling",
    name: "Hl CrossSelling",
    component: HlCrossSelling,
  },
  {
    path: "/hl/propertyDetails",
    name: "Hl PropertyDetails",
    component: HlPropertyDetails,
  },


  {
    path: "/bl/home",
    name: "Bl Home",
    component: BlHome,
  },

  {
    path: "/bl/getdetails",
    name: "Bl GetDetails",
    component: BlGetDetails,
  },
  {
    path: "/bl/otp",
    name: "Bl OTP",
    component: BlOtp,
  },
  {
    path: "/bl/partnerDetails",
    name: "Bl Partner Details",
    component: BlPartnerDetails,
  },
   {
    path: "/bl/loanSummary",
    name: "Bl Loan Summary",
    component: BlLoanSummary,
  },
  {
    path: "/bl/approval",
    name: "Bl Approval",
    component: BlApproval,
  },
  {
    path: "/bl/loanDetails",
    name: "Bl LoanDetails",
    component: BlLoanDetails,
  },
  {
    path: "/bl/businessDetails",
    name: "Bl BusinessDetails",
    component: BlBusinessDetails,
  },
  {
    path: "/bl/gstOtp",
    name: "Bl GstOtp",
    component: BlGstOtp,
  },
  {
    path: "/bl/itrDetails",
    name: "Bl ItrDetails",
    component: BlItrDetails,
  },


  {
    path: "/authentication/error/error404",
    name: "Error Error404",
    component: Error404,
  },
  {
    path: "/authentication/error/error500",
    name: "Error Error500",
    component: Error500,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
