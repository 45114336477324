<template>
  <div class="container top-0 position-sticky z-index-sticky">
     <div class="row">
       <div class="col-12">
         <navbar is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow" btn-background="bg-gradient-success"
        product="Home Loan"
        totalSteps="12"
        link="/hl/home"
           :dark-mode="true" step="2" />
       </div>
     </div>
   </div>
   <main class="mt-lg-0 mt-6 main-content">
     <section>
       <div class="page-header min-vh-100">
         <div class="container">
           <div class="row justify-content-end">
             <div
               class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
               <div
                 class="position-relative bg-gradient-info h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center">
                 <img src="../../assets/img/shapes/pattern-lines.svg" alt="pattern-lines"
                   class="position-absolute opacity-4 start-0" />
                 <div class="position-relative">
                   <img class="max-width-500 w-100 position-relative z-index-2"
                     src="../../assets/img/illustrations/rocket-white.png" alt="rocket" />
                 </div>
                 <h4 class="mt-5 text-white font-weight-bolder">
                   Your journey starts here
                 </h4>
                 <p class="text-white">
                   Discover the best products curated for you in just a few simple steps
                 </p>
                 <div class="position-relative">
                  <img
                    class="max-width-500 w-25 mt-5 position-relative z-index-2"
                    src="../../assets/img/Fint_Logo.png"
                    alt="fintrens"
                  />
                </div>
               </div>
             </div>
             <div class=" col-lg-6 col-md-7 d-flex flex-column mx-lg-0 mx-auto side_Zindex">
              <div class="card card-plain ">
                <div class="card-header   pb-0 text-left">
                  <h6 class="font-weight-bolder text-center text-info text-gradient">
                    Property Details
                  </h6>
                </div>
              <div class="card-body ">
                  <form role="form" @submit="redirect()">
                    <div class="row mt-3">
                      <div class="col-12 col-lg-6"><label> Status Of The Property Transaction</label>
                        <select id="choices-transactionStatus" class="form-control" name="choices-transactionStatus">
                          <option value="" selected>Select Status Of Deal/Booking</option>
                          <option value="Home Loans">yet to pay tocken amount but deal is finished - Disbursment needed on
                            immediate basis</option>
                          <option value="House Renovation Loans">Tocken amount paid - Disbursment needed on immediate
                            basis</option>
                          <option value="Home Extension Loans">Tocken amount paid,Agrement with the DeveloperVendorSeller
                            Done - Disbursement needed on immdediate basis</option>
                        </select>
                      </div>
                      <div class="col-lg-6 col-12">
                        <label>Deal/Booking Date</label>
                        <flat-pickr class=" form-control" placeholder="Enter Date For Booking" v-model="DFB" />
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-12 col-lg-6"><label>Property Located State</label>
                        <select id="choices-state" class="form-control" name="choices-state">
                          <option value="" selected>Select State</option>
                          <option value="Kerala">Kerala</option>
                          <option value="Tamil Nadu">Tamil Nadu</option>
                          <option value="karnataka">karnataka</option>
                        </select>
                      </div>
                      <div class="col-lg-6 col-12">
                        <label >Property Located City</label>
                        <soft-input class="mb-0" placeholder="Enter City" v-model="city" name="city" id="city"
                          type="text" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-lg-6">
                        <label class="mt-3">Property Cost/Estimate</label>
                        <soft-input class="mb-0" placeholder="Enter Estimated Cost" v-model="estimateCost"
                          name="estimateCost" id="estimateCost" type="text" />
                      </div>
                      <div class="col-lg-6 col-12">
                        <label class="mt-3">Expected Loan Amount</label>
                        <soft-input class="mb-0" placeholder="Enter Expected Amount" v-model="expectedAmount"
                          name="expectedAmount" id="expectedAmount" type="text" />
                      </div>
                    </div>
                  
                    <div class="row justify-content-center gx-0">
                      <div class="text-center col-sm-5 col-12">
                        <soft-button color="info" type="button" @Click="redirect()" variant="gradient" full-width
                          class="mt-4 mb-0">NEXT<i class="fas fa-arrow-right text-xs ms-1"></i></soft-button>
                      </div>
                    </div>
                  </form>
                    </div>
                </div>
             </div>
 
           </div>
         </div>
       </div>
     </section>
   </main>
 </template>
<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
import flatPickr from "vue-flatpickr-component";
import * as Choices from "choices.js";


const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";
export default {
  name: "HlPropertyDetails",
  components: {
    Navbar,
    SoftInput,
    SoftButton,
    flatPickr
  },
    
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  mounted() {
    if (document.getElementById("choices-state")) {
      let temp = document.getElementById("choices-state");
      new Choices(temp);
    }
    if (document.getElementById("choices-transactionStatus")) {
      let temp = document.getElementById("choices-transactionStatus");
      new Choices(temp, {
        position: 'bottom',
        itemSelectText:"",
      });
    }
   
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    redirect()
    {
      this.$router.push({ path: "/hl/loanDetails" });
    },
  },
  
};
</script>
<style type="text/css">
.side_Zindex {
  z-index: 1;
}

</style>