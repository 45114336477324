<template>
  <div class="card"  :class="[getClasses(color, variant)]" :style="{height: size+`px`,width: size+ `px`}">
    <div class="row" :class="[textStyle]">
      <div class="col-12 text-center py-3"><i :class="[icon]"></i></div>
      <div class="col-12 text-center px-2"><small><small>{{ header }}</small></small></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SwiperTile",
  props: {
    color: {
      type: String,
      default: "success",
    },
    variant: {
      type: String,
      default: "gradient",
    },
    size: {
      type: Number,
      default: 100,
    },
    textStyle: {
      type: String,
      default: "text-white",
    },
    header: {
      type: String,
      default: " Loan",
    },
    icon: {
      type: String,
      default: "fas fa-coins fa-xl",
    }
  },
  methods: {
    getClasses: (color, variant) => {
      let colorValue;

      if (variant === "gradient") {
        colorValue = `bg-gradient-${color}`;
      } else {
        colorValue = `bg-${color}`;
      }

      return `${colorValue}`;
    },
  },
};
</script>
