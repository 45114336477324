<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow" btn-background="bg-gradient-success"
        link="/pl/home"
          :dark-mode="true" step="8" />
      </div>
     
    </div>
  </div>
  <main class=" main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row justify-content-end">
            <div
              class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
              <div
                class="position-relative bg-gradient-dark h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center">
                <img src="../../assets/img/shapes/pattern-lines.svg" alt="pattern-lines"
                  class="position-absolute opacity-4 start-0" />
                <div class="position-relative">
                  <img class="max-width-500 w-100 position-relative z-index-2"
                    src="../../assets/img/illustrations/rocket-white.png" alt="rocket" />
                </div>
                <h4 class="mt-5 text-white font-weight-bolder">
                  Your journey starts here
                </h4>
                <p class="text-white">
                  Discover the best products curated for you in just a few simple steps
                </p>
                <div class="position-relative">
                  <img
                    class="max-width-500 w-25 mt-5 position-relative z-index-2"
                    src="../../assets/img/Fint_Logo.png"
                    alt="fintrens"
                  />
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
              <div v-if="noLoader" class="card card-plain mt-5">
                <div class="card-header row justify-content-center  pb-0 ">
                  <p class="m-0 font-weight-bolder  text-center text-dark text-gradient">
                    You Are Eligible For Loan Amount Of
                  </p><br/>
                  <h3 class="m-0 p-0 text-xl text-center text-success text-gradient"> ₹ 5,00,000 </h3>
                </div>
                <div class="card-body pb-3">
                  <form role="form" @submit="redirect()">
                      <label class="">Amount</label>
                      <soft-input  placeholder="Enter the required amount" v-model="avail"
                      icon-dir="left"
                      icon="fa fa-coins"
                        name="avail" id="avail"
                        type="text" />
                        <select
                        id="choices-tenure"
                        class="form-control"
                        name="choices-tenure"
                      >
                        <option value="" selected>Select a comfortable tenure</option>
                        <option value="12">12 Months</option>
                        <option value="24">24 Months</option>
                        <option value="36">36 Months</option>
                        <option value="48">48 Months</option>
                        <option value="60">60 Months</option>
                        <option value="72">72 Months</option>
                      </select>
                    <div class="text-center mt-3">
                      <soft-button color="dark" variant="gradient" type="button" full-width @Click="redirect()">
                       NEXT
                      </soft-button>
                    </div>
                  </form>
                </div>
              </div>
              <div v-else class="row container justify-content-center align-items-center">
                <div class="col-4 row justify-content-center">  <DotLoader
                size="40px"
                loading=false
                color="#344767"
   /> </div>
        <div class="col-12 mt-2 text-nowrap">
  <h6 class="text-center text-dark">{{ loaderString }}</h6>
        </div>      
  </div>
            </div>
            
          </div>
         
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
import * as Choices from "choices.js";
import { DotLoader } from "vue3-spinner";

const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";
export default {
  name: "PlOffer",
  components: {
    Navbar,
    SoftInput,
    SoftButton,
    DotLoader
  },
  data() {
    return {
      PinCode: "",
      state: "",
      city: '',
      addressLine1: '',
      addressLine2: '',
      tmpPinCode: "",
      tmpstate: "",
      tmpcity: '',
      tmpaddressLine1: '',
      tmpaddressLine2: '',
      check:'of',
      loadArrayString:['Request is being processed...','Validating Aadhaar information...',
      'Confirming user`s eligibillity...'],
      loaderString:'Request is being processed...',
      loaderCount:0,
      loadTimer:{},
    noLoader:true,
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  mounted(){
    if (document.getElementById("choices-tenure")) {
      var language = document.getElementById("choices-tenure");
      new Choices(language,{
        itemSelectText:" "
      });
    }
  },

  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
  redirect() {
        this.noLoader=false;
        this.loaderString=this.loadArrayString[this.loaderCount];
this.loaderCount++;
if(this.loadArrayString.length<this.loaderCount)
{
  clearTimeout(this.myTimeout);
this.$router.push({ path: "/pl/loanSummary" });
}
else{
  this.myTimOut = setTimeout(() => {
 this.redirect(); 
}, 3000);
}

},

    copyAdresses() {
      this.PinCode = this.tmpPinCode;
      this.state = this.tmpstate;
      this.city = this.tmpcity;
      this.addressLine1 = this.tmpaddressLine1;
      this.addressLine2 = this.tmpaddressLine2;
    },
  },
};
</script>
