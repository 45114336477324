<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow" btn-background="bg-gradient-success"
        link="/pl/home"
          :dark-mode="true" step="5" />
      </div>
    </div>
  </div>
  <main class="mt-5   mt-md-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row justify-content-end">
            <div
              class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
              <div
                class="position-relative bg-gradient-dark h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center">
                <img src="../../assets/img/shapes/pattern-lines.svg" alt="pattern-lines"
                  class="position-absolute opacity-4 start-0" />
                <div class="position-relative">
                  <img class="max-width-500 w-100 position-relative z-index-2"
                    src="../../assets/img/illustrations/rocket-white.png" alt="rocket" />
                </div>
                <h4 class="mt-5 text-white font-weight-bolder">
                  Your journey starts here
                </h4>
                <p class="text-white">
                  Discover the best products curated for you in just a few simple steps
                </p>
                <div class="position-relative">
                  <img
                    class="max-width-500 w-25 mt-5 position-relative z-index-2"
                    src="../../assets/img/Fint_Logo.png"
                    alt="fintrens"
                  />
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
              <div class="card card-plain mt-5">
                <div class="card-header row justify-content-center  pb-0 ">
                  <p class="m-0 font-weight-bolder  text-center text-dark text-gradient">
                    Permanent Address
                  </p>
                </div>
                <div class="card-body pb-3">
                  <form role="form" @submit="redirect()">
                      <label class="">Address Line 1</label>
                      <soft-input class="mb-0" placeholder="Enter Address Line 1" v-model="addressLine1"
                        @blur="() => { this.tmpaddressLine1 = this.addressLine1; }" name="addressLine1" id="addressLine1"
                        type="text" />

                      <label class="mt-3">Address Line 2</label>
                      <soft-input class="mb-0" placeholder="Enter Address Line 2" v-model="addressLine2" name="addressLine2"
                        id="addressLine2" type="text" />
                    <label class="mt-3">City</label>
                    <soft-input class="mb-0" placeholder="Enter City" v-model="city" name="city" id="city" type="text" />
                    
                    <div class="row">   <div class="col-lg-6 col-12"><label class="mt-3">State</label>
                      <select
                      name="state"
                       id="state"
                        class="form-control"
                      >
                        <option value="" selected>Select State</option>
                        <option value="Kerala">Kerala</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="karnataka">Karnataka</option>
                      </select>
                    </div>
                      <div class="col-lg-6 col-12"> <label class="mt-3">PIN Code</label>
                    <soft-input class="mb-0" placeholder="Enter PIN Code" v-model="PinCode" name="PinCode" id="PinCode"
                      type="text" /></div>
                   </div>
                    <div class="d-flex  align-items-center my-2">
                      <soft-checkbox id="termsCheck" name="terms" v-model="check">
                        <template v-slot:label>
                          <label class="custom-control-label font-weight-light">
                          Use above address same as current address  
                           </label>
                        </template>
                      </soft-checkbox>
                    </div>
                    <div class="text-center">
                      <soft-button color="dark" variant="gradient" type="submit" full-width @Click="redirect()">NEXT
                        <i class="fas fa-arrow-right text-xs ms-1"></i></soft-button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import * as Choices from "choices.js";
const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";
export default {
  name: "PlUserAdress",
  components: {
    Navbar,
    SoftInput,
    SoftButton,
    SoftCheckbox,
  },
  data() {
    return {
      PinCode: "",
      state: "",
      city: '',
      addressLine1: '',
      addressLine2: '',
      tmpPinCode: "",
      tmpstate: "",
      tmpcity: '',
      tmpaddressLine1: '',
      tmpaddressLine2: '',
      check:'of'
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },

  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },

  mounted(){
    if (document.getElementById("state")) {
      var language = document.getElementById("state");
      new Choices(language,
      {
        itemSelectText:" ",
        position:"top"
      }
      );
    }
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    redirect() {
      if(this.check=='on') {
      this.$router.push({ path: "/pl/getDetails" });
      }
      else{
      this.$router.push({ path: "/pl/currentAdress" });
      }
    },
    copyAdresses() {
      this.PinCode = this.tmpPinCode;
      this.state = this.tmpstate;
      this.city = this.tmpcity;
      this.addressLine1 = this.tmpaddressLine1;
      this.addressLine2 = this.tmpaddressLine2;
    },
  },
};
</script>
<style>
.choices__item{
  padding-right: 0px !important;
}
</style>