<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-12 mb-lg-0">

          <p><small>
               loans are an ideal solution to fund your  or commercial requirements. Fintrens gives you
              instant  loans for any financial need that you may have.

              Fintrens’s  loan can help you to pay for your next trip, plan a wedding, or cover unexpected medical
              expenses.</small></p>
          <p><small>

              Get a  loan up to ₹40 lakhs without any collateral, with flexible repayment tenures and attractive
              low
              interest rates. Get your loan disbursed in minutes and with minimum documentation.
            </small></p>
          <p><strong>Benefits of Fintrens  loans</strong></p>
            <ul>
              <li><strong><small>Affordable interest rates</small></strong></li>
              <p><small>The Fintrens  loan is affordable; the loan interest rates start at 10.99% p.a. only.</small></p>
              <li><strong><small>Wide range</small></strong></li>
              <p><small>Don’t worry about providing for your financial needs; Fintrens offers
                 loans ranging from ₹50,000 upto ₹40 lakhs.</small></p>
              <li><strong><small>Flexible repayment</small></strong></li>
              <p><small>The  loan repayment tenure ranges between 12 and 60 months. Choose the tenure that best suits you.
                 You can also prepay a part of the loan during the tenure of the loan.</small></p>
              <li><strong><small>Collateral-free loans</small></strong></li>
              <p><small>Fintrens  loans are collateral-free, and you don’t have to pledge any security to avail a  loan.
                 The loan is unsecured and is issued based on income and other  loan eligibility criteria.</small></p>
              <li><strong><small> Digital application and minimum documentation</small></strong></li>
              <p><small>Applying for a Fintrens  loan is simple, and the loan is offered with minimal paperwork.
                 You can getinstant approval and have the loan sanctioned within minutes.</small></p>
            </ul>

          <p><strong>Steps to apply for a Fintrens  loan</strong></p>
          <ul>
            <li><small>Open your Fintrens savings account</small></li>
            <li><small>Download the Fintrens mobile banking app</small></li>
            <li><small>In the app, go to the loans section</small></li>
            <li><small>Click on  loan</small></li>
            <li><small>Simply choose the loan amount you require, enter your tenure, and nominee details, and continue</small></li>
            
          </ul>
          <p><strong>What is a Zero Balance Savings Account?</strong></p>
          <p><strong>Why you should choose to open a zero balance bank account online?</strong></p>
          <li><small><strong>No minimum average balance:</strong> Make the most of the no monthly average balance maintenance requirement.</small></li>
          <li><small><strong>Paperless account opening process:</strong> 
            Choose the convenience of opening a zero balance account online within a few minutes - anytime, anywhere</small></li>
          <li><small><strong>Additional benefits:</strong> A free virtual debit card, and the option to apply for a credit card.</small></li>
          <li><small><strong>Digital payments:</strong> Make digital payments easily using NEFT, UPI, Scan & Pay</small></li>
          <p><small><small>*T&C apply. Credit is at the sole discretion of Fintrens Ventures Private Ltd. and is subject to guidelines issued
              by RBI from time to time. For loans above Rs. 5 lakhs will take up to 48 hours for disbursement with
              additional documentation.</small></small></p>
          <p class="mb-0"><strong><small><small>Disclaimers:</small></small></strong></p>
          <p><small><small>
            Applicable for Resident Indian Individuals only w.e.f. Jun 13, 2022, daily balances in Savings Account above 
            Rs. 50 lakhs will earn 4% interest p.a. Daily balances in Savings Account up to Rs. 50 lakhs will continue 
              to earn 3.50% interest p.a. These interest rates are applicable for Resident Accounts only.
          </small></small></p>
        </div>

      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooterTerms",
};
</script>
