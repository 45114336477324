<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow" btn-background="bg-gradient-success"
          :dark-mode="true" product="Business Loan" link="/bl/home"  totalSteps="7" />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row justify-content-end">
            <div
              class="col-4 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
              <div
                class="position-relative bg-gradient-danger h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center">
                <img src="../../assets/img/shapes/pattern-lines.svg" alt="pattern-lines"
                  class="position-absolute opacity-4 start-0" />
                <div class="position-relative">
                  <img class="max-width-500 w-100 position-relative z-index-2"
                    src="../../assets/img/illustrations/rocket-white.png" alt="rocket" />
                </div>
                <h4 class="mt-5 text-white font-weight-bolder">
                  Your journey starts here
                </h4>
                <p class="text-white">
                  Discover the best products curated for you in just a few simple steps
                </p>
                <div class="position-relative">
                  <img class="max-width-500 w-25 mt-5 position-relative z-index-2" src="../../assets/img/Fint_Logo.png"
                    alt="fintrens" />
                </div>
              </div>
            </div>
            <div class=" col-md-8 d-flex flex-column mx-lg-0 mt-3 mt-lg-0 mx-auto">
              <div class="card card-plain ">
                <SwiperContainer class="mt-5"></SwiperContainer>
                <div class="card-header pb-0 text-center">
                  <div> <i class="fas fa-money-bill text-xs px-1"></i><small><small>Get a Loan up to
                        ₹20,00,000</small></small></div>
                  <div><i class="fas fa-clock text-xs px-1"></i><small><small>Flexible Tenure From 5-10 Years</small></small>
                  </div>
                </div>
                <div class="card-body pb-3">
                  <form role="form" @submit="redirect()">
                    <div class="row">
                      <div class="col-lg-6 col-12">
                        <label >Business PAN Number</label>
                        <soft-input class="mb-0" placeholder="Enter Business PAN Number" v-model="businessPan" name="businessPan" id="businessPan"
                          type="text" />
                      </div>
                      <div class="col-12 col-lg-6"><label>Business Type</label>
                        <select id="choices-partnership" class="form-control" name="choices-partnership">
                          <option value="" selected>Select Business Type</option>
                          <option value="Proprietorship">Proprietorship</option>
                          <option value="Partnership">Partnership</option>
                          <option value="Private Limited">Private Limited</option>
                          <option value="Limited Company">Limited Company</option>
                        </select>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-12 col-lg-6"><label>Industry</label>
                        <select id="choices-businessSegment" class="form-control" name="choices-businessSegment">
                          <option value="" selected>Select Servicing</option>
                          <option value=" Business Segment"> Business Segment</option>
                          <option value="Manufacturing">Manufacturing</option>
                          <option value="Trading">Trading</option>
                          <option value="Servicing">Servicing</option>
                        </select>
                      </div>
                      <div class="col-lg-6 col-12">
                        <label >Expected Loan Amount</label>
                        <soft-input class="mb-0" placeholder="Enter Expected Loan Amount" v-model="expectedAmount"
                          name="expectedAmount" id="expectedAmount" type="text" />
                      </div>
                
                    </div>
                    <div class="row justify-content-center gx-0">
                      <div class="text-center col-sm-5 col-12">
                        <soft-button color="danger" type="button" @Click="redirect()" variant="gradient" full-width
                          class="mt-4 mb-0">NEXT<i class="fas fa-arrow-right text-xs ms-1"></i></soft-button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </main>
  <div>
    <AdsTileContainer class="mx-0"></AdsTileContainer>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <footer-terms></footer-terms>
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script>
import * as Choices from "choices.js";
import Navbar from "@/examples/PageLayout/Navbar.vue";
import Footer from "@/examples/Footer.vue";
import FooterTerms from "@/examples/FooterTerms.vue";
import SoftButton from "@/components/SoftButton.vue";
import AdsTileContainer from "../common/AdsTileContainer.vue";
import SwiperContainer from "./SwiperContainer.vue";
import SoftInput from "@/components/SoftInput.vue";



const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";
export default {
  name: "BlVehicleLoan",
  components: {
    Navbar,
    SoftButton,
    Footer,
    FooterTerms,
    AdsTileContainer,
    SwiperContainer,
    SoftInput
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  mounted() {
    if (document.getElementById("choices-businessSegment")) {
      let temp = document.getElementById("choices-businessSegment");
      new Choices(temp);
    }
    if (document.getElementById("choices-partnership")) {
      let temp = document.getElementById("choices-partnership");
      new Choices(temp);
    }
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    redirect() {
      this.$router.push({ path: "/bl/businessDetails" });
    },

  },

};
</script>
<style type="text/css">
.choices {
  margin-bottom: 0px !important;
}
</style>