<template>
  <div class="col-12" :class="[getClasses(color, variant),getSize(size)]" >
    <div class="row">
      <div class="col-12 h4 text-center pt-3 mb-1" :class="[textStyle]">{{ header }}</div>
      <div class="col-12 text-center p-0" :class="[textStyle]">{{ subHeader }}</div>
      <div class="col-12 text-center">
        <img v-if="img"
      :src="img"
      :alt="alt"
      :style="{height: imgHeight+`px`}"
    /></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdsTile",
  props: {
    color: {
      type: String,
      default: "success",
    },
    variant: {
      type: String,
      default: "fill",
    },
    size: {
      type: Number,
      default: 6,
    },
    textStyle: {
      type: String,
      default: "text-white",
    },
    header: {
      type: String,
      default: "",
    },
    subHeader: {
      type: String,
      default: "",
    },
    img: {
      type: String,
      default: "",
    },
    imgHeight: {
      type: Number,
      default: 100
    },
  },
  methods: {
    getClasses: (color, variant) => {
      let colorValue;

      if (variant === "gradient") {
        colorValue = `bg-gradient-${color}`;
      } else {
        colorValue = `bg-${color}`;
      }

      return `${colorValue}`;
    },
    getSize: (size) => {
      return `col-md-`+`${size}`;
    },
  },
};
</script>
